
<template>
    <div class="pagination">
        <span v-if="digit(page) > 1"><router-link :to="`/gallery/${gallery}/${subtract(page, 1)}/`">上一页</router-link></span>
        <span v-if="digit(page) != 1"><router-link :to="`/gallery/${gallery}/`">1</router-link></span>
        <span v-if="(subtract(page, 3)) > 1">…</span>
        <span v-if="(subtract(page, 2)) > 1"><router-link :to="`/gallery/${gallery}/${subtract(page, 1)}/`">{{subtract(page, 2)}}</router-link></span>
        <span v-if="(subtract(page, 1)) > 1"><router-link :to="`/gallery/${gallery}/${subtract(page, 1)}/`">{{subtract(page, 1)}}</router-link></span>
        <span v-if="digit(page)" class="current">{{digit(page)}}</span>
        <span v-if="digit(amountFomat) > (increase(page, 1))"><router-link :to="`/gallery/${gallery}/${increase(page, 1)}/`">{{increase(page, 1)}}</router-link></span>
        <span v-if="digit(amountFomat) > (increase(page, 2))"><router-link :to="`/gallery/${gallery}/${increase(page, 2)}/`">{{increase(page, 2)}}</router-link></span>
        <span v-if="digit(amountFomat) > (increase(page, 3))">…</span>
        <span v-if="digit(amountFomat) != digit(page)"><router-link :to="`/gallery/${gallery}/${digit(amountFomat)}/`">{{digit(amountFomat)}}</router-link></span>
        <span v-if="digit(page) < digit(amountFomat)"><router-link :to="`/gallery/${gallery}/${increase(page, 1)}/`">下一页</router-link></span>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "GalleryPagination",
        props: ["gallery", "page"],
        methods: {
            digit(num){
               if(! num) return 1;
               num = parseInt(num);
               if(isNaN(num)) return 1;
               return num;
            },
            increase(former, latter){
                if(! former || ! latter) return;
                former = parseInt(former);
                latter = parseInt(latter);
                if(isNaN(former)) former = 1;
                if(isNaN(latter)) latter = 1;
                if(former == 0 && latter == 0) return;
                return former + latter;
            },
            subtract(former, latter){
                if(! former || ! latter) return;
                former = parseInt(former);
                latter = parseInt(latter);
                if(isNaN(former)) former = 1;
                if(isNaN(latter)) latter = 1;
                if(latter == 0 || (former == 1 && latter == 1)) return;
                return former - latter;
            }
        },
        computed: {
            ...mapGetters('atlas', ['getAmount']),
            amountFomat(){
                if(! this.gallery) return;
                let gallery;
                if(this.gallery == "用户头像") gallery = "portrait";
                if(this.gallery == "背景壁纸") gallery = "wallpaper";
                return this.getAmount(gallery);
            }
        }
    }
</script>


<style>
    .pagination {
        width: 100%;
        height: 4em;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .pagination a {
        text-decoration: none;
        color: rgba(255, 255, 255, .9);
    }
    .pagination a:hover {
        color: #49b1f5;
    }
    .pagination span {
        margin-left: .45em;
        margin-right: .45em;
        font-family: "华文楷体";
        font-size: 1.28em;
        font-weight: 1000;
        color: rgba(0, 0, 0, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
</style>