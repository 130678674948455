

// 媒体数据相关配置
export default {

    namespaced: true,

    mutations: {
        MUSIC_INIT(state, val){
            state.music = val;
        },

        SET_PLAY_MUSIC_INDEX(state, index) {
            if (index == state.music.index) {return;}
            state.play_music_index = index;
        },
        SET_PLAY_MUSIC_LIST(state, musicList) {
            state.play_music_list = musicList;
        },
        ADD_MUSIC_TO_LIST(state, { music, index }) {
            if (index) {
                state.play_music_list.splice(index, 0, music);
            } else {
                state.play_music_list.push(music);
            }
        },
        REMOVE_MUSIC_FROM_LIST(state, index) {
            state.play_music_list.splice(index, 1);
        },

        VIDEO_INIT(state, val){
            state.video = val;
        }
    },

    actions: {
        playMusic({state, commit}, index) {
            if (! state.aplayer) {return;}
            commit('SET_PLAY_MUSIC_INDEX', index);
            state.aplayer.list.switch(index);
            if (!state.aplayer.playing) {state.aplayer.play();}
        },
        updateMusicList({state, commit}, list) {
            if (state.aplayer && state.aplayer.playing) {state.aplayer.pause();}
            commit('SET_PLAY_MUSIC_LIST', list);
            if (state.aplayer) {
                state.aplayer.list.clear();
                state.aplayer.list.add(list);
            }
        },
        addMusic({state, commit}, music) {
            if (state.play_music_list.length <= 0) {state.play_music_list = [];}
            index = state.play_music_index;
            commit('ADD_MUSIC_TO_LIST', {music, index});
            if (state.aplayer) {state.aplayer.list.add(music, index);}
        },
        removeMusic({state, commit}, index) {
            if (index == state.aplayer.list.index) {
                if (state.aplayer.list.hasNext()) {
                    state.aplayer.next();
                } else {
                    state.aplayer.prev();
                }
            }
            commit('REMOVE_MUSIC_FROM_LIST', index);
            if (this.aplayer) {this.aplayer.list.remove(index);}
        }
    },

    state: {
        music: null,            // 所有音乐对象
        aplayer: null,          // miniAplayer实例对象
        play_music_list: [],    // 正在播放信息
        play_music_index: null, // 正在播放单曲的序号
        video: null,
    },

    getters: {

        musicAll: state=> {
            if(! state.music) return;
            return state.music;
        },
        musicPlayIndex: state=> {
            return state.play_music_index;
        },
        musicPlayList: state=> {
            return state.play_music_list;
        },
        musicAppointkey: state=> {
            return (key) => {
                if (!state.music || !key) return {};
                if (key === 'prefer') {
                    const filtered = state.music.filter(item => item.prefer === 1);
                    return filtered.length > 0 ? { 1: filtered } : {};
                }

                const typelist = ['group', 'label', 'language'];
                if (typelist.includes(key)) {
                    return state.music.reduce((acc, item) => {
                        let groupName = item[key];
                        if (!acc[groupName]) {
                            acc[groupName] = [];
                        }
                        acc[groupName].push(item);
                        return acc;
                    }, {});
                }

                if (key === 'artist') {
                    return state.music.reduce((acc, item) => {
                        let artists = item.artist.split(/、|\/|\|/);
                        artists.forEach(artist => {
                            artist = artist.trim();
                            if (!acc[artist]) {
                                acc[artist] = [];
                            }
                            acc[artist].push(item);
                        });
                        return acc;
                    }, {});
                }
                
                return {};
            };
        },

        musicAppoint: state=> {
            return (groups, artists, prefer, languages) => {
                if (!state.music) return [];
                if (!groups && !artists && !prefer && !languages) return state.music;
                
                return state.music.filter(item => {
                    if (groups) return groups.some(group => item.group.toUpperCase().includes(group.toUpperCase()));
                    if (artists) return artists.some(artist => item.artist.toUpperCase().includes(artist.toUpperCase()));
                    if (prefer) return item.prefer === prefer;
                    if (languages) return languages.some(language => item.language.toUpperCase().includes(language.toUpperCase()));
                    return true;
                });
            };
        },
        musicAppointLabel: state=> {
            return (label) => {
                if (!state.music || !label) return [];
                return state.music.filter(item => item.label.toUpperCase().includes(label.toUpperCase()));
            };
        },
    }
}