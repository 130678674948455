
<template>
    <div class="article">
        <!--article-label、article-content、article.tags-->
        <template>
            <div class="article-label">
                <div class="label-value">
                    <p >文章{{labelFomat}}</p>
                </div>
            </div>
            <div class="article-verification" v-if="articleContent.verification">
                <el-input placeholder="输入密码查看文章" v-model="encryption" show-password @keyup.enter.native="ciphertext(uuid, encryption)"></el-input>
            </div>
            <div class="article-content" v-html="contentFormat" v-if="! articleContent.verification" v-viewer="viewerOptions"></div>
            <div class="article-tags">
                <div class="tags-item" v-for="(tag, index) in tagsFormat" :key="index">
                    <router-link :to="`/tags/${tag}/`">{{tag}}</router-link>
                </div>
            </div>
        </template>

        <!--article-signature、article-appreciate-->
        <template>
            <ArticleContentSignature :articleContentSignature="articleContentSignature"/>
        </template>

        <!--article-next、article-related-->
        <template>
            <ArticleContentRelation :articleContentRelation="articleContentRelation"/>
        </template>

        <!--article-comment-->
        <template>
            <ArticleContentComment :title="title" :verification="articleContent.verification" :articleContentComment="articleContentComment"/>
        </template>
    </div>
</template>


<script>
    import ArticleContentComment from '@/components/pages/article/ArticleComment.vue';
    import ArticleContentSignature from '@/components/pages/article/ArticleSignature';
    import ArticleContentRelation from '@/components/pages/article/ArticleRelation.vue';
    export default {
        name: "ArticleContent",
        components: {ArticleContentRelation, ArticleContentSignature, ArticleContentComment},
        props: ['title', 'uuid', 'ciphertext', 'articleContent', 'articleContentSignature', 'articleContentRelation', 'articleContentComment'],
        data(){
            return{
                encryption: "",
                viewerOptions: {
                    inline: false,
                    button: true,
                    navbar: false,
                    title: false,
                    toolbar: true,
                    tooltip: true,
                    movable: true,
                    zoomable: true,
                    rotatable: true,
                    scalable: true,
                    transition: true,
                    fullscreen: true,
                }
            }
        },
        computed: {
            labelFomat(){
                if(! this.articleContent.label) return;
                if(this.articleContent.label === "原创" || this.articleContent.label === 1) return "原创";
                if(this.articleContent.label === "转载" || this.articleContent.label === 2) return "转载";
                if(this.articleContent.label === "翻译" || this.articleContent.label === 3) return "翻译";
            },
            contentFormat(){
                if(! this.articleContent.content || (! typeof this.articleContent.content === "string")) return;
                return this.articleContent.content;
            },
            tagsFormat(){
                if(! this.articleContent.tags || ! this.articleContent.tags.length) return;
                let results = new Array();
                this.articleContent.tags.forEach( (item)=> {
                    if(item) results.push(item);
                })
                return results;
            }
        }
    }
</script>


/**article-label */
<style scoped>
    .article {
        width: 90%;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .9);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
    .article-label {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: right;
    }
    .label-value {
        border-radius: 4px;
        padding-top: .25em;
        padding-left: .5em;
        padding-right: .5em;
        padding-bottom: .25em;
        background: #f2711c;
    }
    .label-value p {
        color: #fff;
        font-size: 1.08em;
        font-family: "华文行楷";
    }
    .article-verification {
        padding-top: 1.5em;
    }
    .article-content {
        padding-top: 1.5em;
    }
</style>

/**article-content */
<style>
    #layout-content .article-contents .article-content h1, h2, h3 {
        color: #515151;
    }
    #layout-content .article-contents .article-content p {
        line-height: 1.5;
        padding-top: .55em;
        padding-bottom: .55em;
    }
    #layout-content .article-contents .article-content blockquote {
        margin: 0 0 1rem;
        padding: 0.1rem 0.8rem;
        border-left: 0.2rem solid #49b1f5;
        background-color: rgba(100, 250, 250, 0.2);
        color: #323B45;
        border-top-right-radius: .25em;
        border-bottom-right-radius: .25em;
    }
    #layout-content .article-contents .article-content img {
        width: 100%;
        height: 100%;
        border-radius: .25em;
    }
    #layout-content .article-contents .article-content ul {
        margin-left: 2em;
        margin-top: 0.4rem;
    }
    #layout-content .article-contents .article-content ul li {
        padding-left: .3rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    #layout-content .article-contents .article-content a {
        color: #127abe;
        text-decoration: none;
    }
    #layout-content .article-contents .article-content a:hover {
        text-decoration: underline;
    }

    #layout-content .article-contents .article-content pre {
        background: #363636;
        border-radius: .5em;
        margin-bottom: 1.5em;
    }

    #layout-content .article-contents .article-content pre code p {
        line-height: 1.5;
        font-size: 1.02em;
        font-family: "宋体";
        color: #fff;
    }
    #layout-content .article-contents .article-content pre code .code-serial {
        position: absolute;
        left: 1em;
        top: .6em;
        user-select: none;
        pointer-events: none;
        color: #8D8D8D8D;
    }
    #layout-content .article-contents .article-content pre code .code-content{
        position: relative;
        padding-left: 3.5em;
        padding-right: 3.5em;
        word-break:break-all;
        white-space:pre-wrap;
    }
    #layout-content .article-contents .article-content pre code .code-interval {
        margin-top: -2em;
    }
    #layout-content .article-contents .article-content pre code .code-annotation {
        color: #B0B0B0;
        font-family: "华文楷体";
    }
</style>

/**article-tags */
<style scoped>
    .article-tags {
        padding-top: .8em;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: left;
    }
    .tags-item {
        margin-right: .5em;
        border-radius: 4px;
        padding-top: .1em;
        padding-left: .4em;
        padding-right: .4em;
        padding-bottom: .1em;
        background: #009c95;
    }
    .tags-item a {
        font-size: 1.06em;
        font-family: "华文楷体";
        color: #fff;
        text-decoration: none;
    }
</style>