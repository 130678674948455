
<template>
    <div>
        <div v-for="item in picture" :key="item.route" v-viewer="viewerOptions">
            <template v-if="item.type == 'single'">
                <div class="list" :style="'height:' + (item.height) + 'px'">
                    <div class='list-half' :style="'width:' + (item.width) + 'px'">
                        <el-image :src="item.route" lazy :style="'height:' + (item.height) + 'px'">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </div>
                </div>
            </template>
            <template v-if="item.type == 'double'">
                <div class="list" :style="'height:' + (item.height) + 'px'">
                    <div class='list-half' :style="'width:' + (item.formerWidth) + 'px'">
                        <el-image :src="item.formerRoute" lazy :style="'height:' + (item.height) + 'px'">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </div>
                    <div class='list-half' :style="'width:' + (item.latterWidth) + 'px'">
                        <el-image :src="item.latterRoute" lazy :style="'height:' + (item.height) + 'px'">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </div>
                </div>
            </template>
            <template v-if="item.type == 'triple'">
                <div class="list" :style="'height:' + (item.height) + 'px'">
                    <div class='list-half' :style="'width:' + (item.formerWidth) + 'px'">
                        <el-image :src="item.formerRoute" lazy :style="'height:' + (item.height) + 'px'">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </div>
                    <div class='list-half' :style="'width:' + (item.centreWidth) + 'px'">
                        <el-image :src="item.centreRoute" lazy :style="'height:' + (item.height) + 'px'">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </div>
                    <div class='list-half' :style="'width:' + (item.latterWidth) + 'px'">
                        <el-image :src="item.latterRoute" lazy :style="'height:' + (item.height) + 'px'">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>


<script>
    export default {
        name: "GalleryContent",
        props: ['photograph'],
        data(){
            return{
                picture: null,
                viewerOptions: {
                    inline: false,
                    button: true,
                    navbar: false,
                    title: false,
                    toolbar: true,
                    tooltip: true,
                    movable: true,
                    zoomable: true,
                    rotatable: true,
                    scalable: true,
                    transition: true,
                    fullscreen: true,
                }
            }
        },
        methods: {
            singleFomat(pointer){
                let result = new Object();
                let coefficient = this.photograph[pointer].width / 1072;
                result.width = 1072;
                result.type = "single";
                result.route = this.photograph[pointer].route;
                result.height = this.photograph[pointer].height / coefficient;
                if(this.photograph[pointer].group) result.group = this.photograph[pointer].group;
                if(this.photograph[pointer].singer) result.singer = this.photograph[pointer].singer;
                return result;
            },
            doubleFomat(pointer){
                let result = new Object(), coefficient = null, height, formerWidth, latterWidth;
                if(this.photograph[pointer].height >= this.photograph[pointer + 1].height){
                    height = this.photograph[pointer].height;
                    formerWidth = this.photograph[pointer].width;
                    latterWidth = this.photograph[pointer + 1].width * (this.photograph[pointer].height / this.photograph[pointer + 1].height);
                }else{
                    height = this.photograph[pointer + 1].height;
                    latterWidth = this.photograph[pointer + 1].width;
                    formerWidth = this.photograph[pointer].width * (this.photograph[pointer + 1].height / this.photograph[pointer].height);
                }
                result.type = "double";
                coefficient = (formerWidth + latterWidth) / 1068;
                result.height = height / coefficient;
                result.formerWidth = formerWidth / coefficient;
                result.latterWidth = latterWidth / coefficient;
                result.formerRoute = this.photograph[pointer].route;
                result.latterRoute = this.photograph[pointer + 1].route;
                if(this.photograph[pointer].group) result.formerGroup = this.photograph[pointer].group;
                if(this.photograph[pointer].singer) result.formerSinger = this.photograph[pointer].singer;
                if(this.photograph[pointer + 1].group) result.latterGroup = this.photograph[pointer + 1].group;
                if(this.photograph[pointer + 1].singer) result.latterSinger = this.photograph[pointer + 1].singer;
                return result;
            },
            tripleFomat(pointer){
                let result = new Object(), coefficient = null, height, formerWidth, centreWidth, latterWidth;
                if(this.photograph[pointer].height >= this.photograph[pointer + 1].height && this.photograph[pointer].height >= this.photograph[pointer + 2].height){
                    height = this.photograph[pointer].height;
                    formerWidth = this.photograph[pointer].width;
                    centreWidth = this.photograph[pointer + 1].width * (this.photograph[pointer].height / this.photograph[pointer + 1].height);
                    latterWidth = this.photograph[pointer + 2].width * (this.photograph[pointer].height / this.photograph[pointer + 2].height);
                }
                if(this.photograph[pointer + 1].height >= this.photograph[pointer].height && this.photograph[pointer + 1].height >= this.photograph[pointer + 2].height){
                    height = this.photograph[pointer +1].height;
                    centreWidth = this.photograph[pointer + 1].width;
                    formerWidth = this.photograph[pointer].width * (this.photograph[pointer + 1].height / this.photograph[pointer].height);
                    latterWidth = this.photograph[pointer + 2].width * (this.photograph[pointer + 1].height / this.photograph[pointer + 2].height);
                }
                if(this.photograph[pointer + 2].height >= this.photograph[pointer].height && this.photograph[pointer + 2].height >= this.photograph[pointer + 1].height){
                    height = this.photograph[pointer + 2].height;
                    latterWidth = this.photograph[pointer + 2].width;
                    formerWidth = this.photograph[pointer].width * (this.photograph[pointer + 2].height / this.photograph[pointer].height);
                    centreWidth = this.photograph[pointer + 1].width * (this.photograph[pointer + 2].height / this.photograph[pointer + 1].height);
                }
                result.type = "triple";
                coefficient = (formerWidth + centreWidth + latterWidth) / 1064;
                result.height = height / coefficient;
                result.formerWidth = formerWidth / coefficient;
                result.centreWidth = centreWidth / coefficient;
                result.latterWidth = latterWidth / coefficient;
                result.formerRoute = this.photograph[pointer].route;
                result.centreRoute = this.photograph[pointer + 1].route;
                result.latterRoute = this.photograph[pointer + 2].route;
                if(this.photograph[pointer].group) result.formerGroup = this.photograph[pointer].group;
                if(this.photograph[pointer].singer) result.formerSinger = this.photograph[pointer].singer;
                if(this.photograph[pointer + 1].group) result.centreGroup = this.photograph[pointer + 1].group;
                if(this.photograph[pointer + 2].group) result.latterGroup = this.photograph[pointer + 2].group;
                if(this.photograph[pointer + 1].singer) result.centreSinger = this.photograph[pointer + 1].singer;
                if(this.photograph[pointer + 2].singer) result.latterSinger = this.photograph[pointer + 2].singer;
                return result;
            }
        },
        watch: {
            "photograph": {
                immediate: true,
                handler(val){
                    if(! val || val.length < 1) return;
                    let pointer = 0;
                    this.picture = new Array();
                    while(pointer < this.photograph.length - 1){
                        let result;
                        let index = Math.floor((Math.random()*100) + 1);
                        if(pointer < this.photograph.length - 3){
                            if(index <= 30){
                                result = this.singleFomat(pointer);
                                pointer += 1;
                            }else if(index <= 40){
                                result = this.doubleFomat(pointer);
                                pointer += 2;
                            }else{
                                result = this.tripleFomat(pointer);
                                pointer += 3;
                            }
                        }else if(pointer < this.photograph.length - 2){
                            if(index <= 40){
                                result = this.singleFomat(pointer);
                                pointer += 1;
                            }else{
                                result = this.doubleFomat(pointer);
                                pointer += 2;
                            }
                        }else{
                            result = this.singleFomat(pointer);
                            pointer += 1;
                        }
                        this.picture.push(result);
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .list {
        display: flex;
        padding-top: 2px;
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 2px;
        overflow: hidden;
        justify-content: space-between;
    }
    .list .list-half {
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: 0;
        cursor: pointer;
        transform: translate(-50%, -50%);
        height: auto;
    }
</style>

<style>
    .viewer-navbar .viewer-list {
        height: 80px !important;
    }
    .viewer-navbar ul li {
        width: 130px !important;
        height: 80px !important;
    }
</style>