
<template>
    <div>
        <div class="card-author">
            <div class="author-head">
                <el-image :src="getCard.head" lazy>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </div>
            <div class="author-name">{{getCardName}}</div>
            <div class="author-autograph">
                <template v-for="(autograph, index) in getCardAutograph(getCard.autograph)">
                    <span :key="index" v-if="autograph && index <= 4">{{autograph}}</span>
                </template>
            </div>
        </div>
        <div class="card-data">
            <div class="data-article">
                <div class="article-label"><router-link  to="/archive/">文章</router-link></div>
                <div class="article-value"><router-link  to="/archive/">{{getQuantity | articleFomat}}</router-link></div>
            </div>
            <div class="data-tags">
                <div class="tags-label"><router-link  to="/tags/">标签</router-link></div>
                <div class="tags-value"><router-link  to="/tags/">{{Object.keys(getTags ? getTags : {}).length | tagsFomat}}</router-link></div>
            </div>
            <div class="data-classify">
                <div class="classify-label"><router-link  to="/classify/">分类</router-link></div>
                <div class="classify-value"><router-link  to="/classify/">{{Object.keys(getClassifys ? getClassifys : {}).length | articleFomat}}</router-link></div>
            </div>
        </div>
        <div class="card-home">
            <router-link  to="/">
                <div class="home-address"><i class="iconfont icon-dingwei"></i>个人主页</div>
            </router-link>
        </div>
        <div class="card-chain">
            <div class="chain-address">
                <a  :href="getCard.github" target="_bank"><span class="iconfont icon-github"></span></a>
                <a  :href="getCard.email" target="_bank"><span class="iconfont icon-mail"></span></a>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "Card",
        data(){
            return{}
        },
        methods: {
            getCardAutograph(){
                let autograph = [];
                let val = this.getCard.autograph;
                if(! val || (typeof val != "string") ||
                (val.constructor !== String) || (Object.prototype.toString.call(val) != "[object String]")){
                    desc[0] = "介绍有误";
                    return autograph;
                }

                val = val.replace(/(^\s*)|(\s*)$/g,"");
                if(val >= 70) val = val.slice(0,69);

                if(val.indexOf("<br>") != -1){
                    let results = val.split("<br>");
                    if(results.length >= 1){
                        results.forEach( (item, index)=> {
                            let result = item.replace(/(^\s*)|(\s*)$/g,"");
                            autograph[index] = result.length > 14 ? result.slice(0, 14) : result;
                        })
                        return autograph;
                    }
                    autograph[0] = "介绍为空";
                    return autograph;

                }
                autograph[0] = val;
                return autograph;
            }
        },
        computed: {
            ...mapGetters('author', ['getCard']),
            ...mapGetters('article', ['getQuantity', 'getTags', 'getClassifys']),
            getCardName(){
                let val = this.getCard.name;
                if(! val || (typeof val != "string") ||
                (val.constructor !== String) || (Object.prototype.toString.call(val) != "[object String]")){
                    return "昵称有误";
                }
                val = val.replace(/(^\s*)|(\s*)$/g,"");
                if(val.length > 11){
                    if(val[10] === "：" || val[10] === "~" || val[10] === ":" || val[10] === "-" || val[10] === "--"){
                        return val.slice(0,10);
                    }
                    return val.slice(0,11);
                }
                return val;
            },
        },
        filters: {
            articleFomat(val){
                if(! (typeof val === 'number') || isNaN(val)){
                    return 0;
                }
                if(val <= 999){
                    return val;
                }
                return "999";
            },
            tagsFomat(val){
                if(! (typeof val === 'number') || isNaN(val)){
                    return 0;
                }
                if(val <= 9999){
                    return val;
                }
                return "9999";
            }
        }
    }
</script>


<style scoped>
    .card-author, .card-data, .card-home, .card-chain {
        padding-left: 10%;
        padding-right: 10%;
        width: 80%;
        text-align: center;
    }
    .card-author {
        padding-top: 1em;
    }


    .author-head, .author-name {
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .author-head {
        height: 8.32em;
    }
    .author-head .el-image, .el-image__inner {
        width: 8.32em;
        height: 8.32em;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .author-name {
        height: 1.8em;
        font-weight: 800;
        font-family: "华文楷体";
        font-size: 1.78em;
        line-height: 1.8;
        white-space:nowrap;
    }
    .author-autograph {
        margin-top: .2em;
        color: #280000;
    }
    .author-autograph span {
        font-weight: 100;
        font-size: 1em;
        font-family: "华文楷体";
        line-height: 1.4;
        white-space:nowrap;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }

    .card-data{
        margin-top: .5em;
        display: flex;
        justify-content: space-around;
    }
    .data-article, .data-tags, .data-classify {
        height: 3.5em;
    }
    .article-label, .tags-label, .classify-label, .article-value, .tags-value, .classify-value {
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .article-label, .tags-label, .classify-label {
        height: 60%;;
    }
    .article-label a, .tags-label a, .classify-label a {
        font-family: "华文行楷";
        font-size: 1.42em;
        font-weight: 300;
        color: #280000;
    }
    .article-value, .tags-value, .classify-value {
        height: 40%;
    }
    .article-value a, .tags-value a, .classify-value a {
        font-family: "华文隶书";
        font-size: 1.08em;
        font-weight: 100;
        color: #380000;
    }

    .card-home {
        margin-top: .8em;
    }
    .home-address {
        height: 2.5em;
        width: 100%;
        font-family: "华文楷体";
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
        background: skyblue;
    }
    .home-address a {
        color: #180000;
    }
    .home-address:hover {
        background: orange;
    }
    .home-address i {
        margin-right: .5em;
    }


    .card-chain {
        margin-top: .4em;
        padding-bottom: 1em;
    }

    .chain-address {
        height: 2em;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
        z-index: 1;
        position: relative;
    }
    .chain-address a {
        color: #280000;
    }
    .chain-address span {
        font-size: 1.2em;
        margin-left: .2em;
        margin-right: .2em;
    }
    .chain-address span:hover {
        color: #49b1f5;
    }
    a {
        text-decoration: none;
    }
</style>