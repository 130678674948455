
<template>
  <div v-show="! loading_state">
    <div id="page-app">
      <!-- app-header -->
      <div id="app-header" :class="header_state"><AppHeader/></div>

      <!-- app-layout-->
      <div id="app-layout">
          <router-view :BackTop="BackTop"/>
      </div>

      <!-- app-footer -->
      <div id="app-footer"><AppFooter/></div>
    </div>

    <!-- aplayer-mini -->
    <div id="popup-aplayer"><AplayerMini/></div>

    <!-- effect -->
    <div id="page-effect"><SpecialEffects/></div>

    <!-- local-search -->
    <template><LocalSearch/></template>

    <!-- gear-tool -->
    <template><Levitation :BackTop="BackTop" /></template>

    <!-- loading-progress -->
    <template><Progress/></template>
  </div>
</template>


<script>
  import {Loading} from 'element-ui';
  import {mapState, mapMutations} from 'vuex';
  import Progress from '@/components/popup/Progress.vue';
  import AppHeader from '@/components/overall/AppHeader.vue';
  import AppFooter from '@/components/overall/AppFooter.vue';
  import Levitation from '@/components/popup/Levitation.vue';
  import LocalSearch from '@/components/popup/LocalSearch.vue';
  import AplayerMini from '@/components/popup/AplayerMini.vue';
  import SpecialEffects from '@/components/overall/SpecialEffects.vue';
  export default {
    name: 'App',
    components: {AppHeader, AppFooter, LocalSearch, Levitation, AplayerMini, SpecialEffects, Progress},
    data(){
       return{
        loading: null,
        header_state: "header-hide"
      }
    },

    methods: {
      ...mapMutations('popup', {roll: 'ROLL', levitationShow: 'LEVITATION_SHOW', levitationHide:'LEVITATION_HIDE'}),

      // 初始化看板娘
      live2dInit(){
        window.L2Dwidget.init({
          "model": {
            jsonPath: "./libs/live2dw/live2d-widget-model-koharu/assets/koharu.model.json",
            "scale": 1
          },
          "display": {
            "position": "left",
            "width": 250,
            "height": 330,
            "hOffset": 10,
            "vOffset": 50,
            zIndex: 10000
          },
          "mobile": {
            "show": false,
            "scale": 0.5
          },
          "react": {
            opacity: 1,
            "opacityDefault": 0,
            "opacityOnHover": 0
          }
        });
      },

      // 保存滚动值，这是兼容的写法（离开页面关闭监听）
      HandleScroll(){
        this.roll(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
      },

      // 滚动条回到顶部
      BackTop(){
        if (this.scroll_val > 0){
          document.documentElement.scrollTop = 0;
        }
      }
    },

    computed: {
        ...mapState('popup', ['loading_state', 'scroll_val', 'levitation_show']),
    },


    watch: {
      // 监察加载动画
      "loading_state": {
        immediate: true,
        handler(val, old){

          // 如果展示条件为真
          if(val){

            // 如果加载已显示则跳过
            if(old) return;

            // 加载页面显示
            this.loading = Loading.service({
              text: 'Loading…',
              background: "rgba(250, 250, 250, 1)"
            })

          // 如果展示条件为假
          }else{

            // 如果展示页面未渲染则跳过
            if(this.loading){
              this.loading.close();
            }
          }
        }
      },

      // 监察滚动条变化并作出相应的动作
      "scroll_val": {
        immediate: true,
        handler(val, old){

          // 当滚动距离为0（没有任何滚动）的情况下
          if(val === 0){
            this.header_state = "header-hide";                           // 隐藏header导航栏
            if(this.levitation_show) this.levitationHide();              // 隐藏回顶部图标
            return;
          }

          if(! this.levitation_show) this.levitationShow();              // 隐藏回顶部图标

          // 当向上滚动的情况下
          if(old >= val){
            this.header_state = "header-show";                           // 展示回顶部图标
            return;
          }

          // 当向下滚动的情况下
          this.header_state = "header-hide";                              // 隐藏header导航栏
        }
      }
    },

    mounted(){
      this.$nextTick(() => {this.live2dInit();})
      window.addEventListener('scroll', this.HandleScroll, true); // 调用window监听滚动条变化
    },

    destroyed(){
      window.removeEventListener('scroll', this.HandleScroll);    // 离开该页面需要移除这个监听的事件，不然会报错
    }
}
</script>


/** 字体引用 */
<style>
  @font-face {
    font-family: "仿宋";
    src: url('@/assets/font/fangsong.ttf');
    font-display: swap;
  }
  @font-face {
    font-family: "华文仿宋";
    src: url('@/assets/font/huawenfangsong.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "华文楷体";
    src: url('@/assets/font/huawenkaiti.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "华文隶书";
    src: url('@/assets/font/huawenlishu.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "华文宋体";
    src: url('@/assets/font/huawensongti.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "华文行楷";
    src: url('@/assets/font/huawenxingkai.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "华文中宋";
    src: url('@/assets/font/huawenzhongsong.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "楷体";
    src: url('@/assets/font/kaiti.ttf');
    font-display: swap;
  }
  @font-face {
    font-family: "隶书";
    src: url('@/assets/font/lishu.TTF');
    font-display: swap;
  }
  @font-face {
    font-family: "新宋体";
    src: url('@/assets/font/xinsongti.ttc');
    font-display: swap;
  }
</style>


<style>
  * {
    margin: 0;
    padding: 0;
  }

  html, body, #app {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
  }

  /**修改全局的滚动条（仅在谷歌浏览器中起效）*/
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: skyblue;
  }

  /*element ui表格滚动条*/
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px;
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: skyblue;
  }

  /*element ui加载样式*/
  .el-loading-mask.is-fullscreen .el-loading-spinner {
      margin-top: -2em;
  }
  .el-loading-mask.is-fullscreen .el-loading-spinner .circular {
      height: 4em;
      width: 4em;
  }
  .el-loading-spinner .el-loading-text {
      font-size: 1.28em;
      font-family: "华文隶书";
  }

  #page-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: no-repeat center center fixed;
    background-size: cover;
  }
  #page-bg {
    z-index: -9999;
  }

  #page-app {
    width: 100%;
    height: 100%;
  }

  #app-header {
    z-index: 999;
    width: 100%;
    height: 70.8px;
  }

  #app-layout {
    z-index: 99;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
  }

  #app-footer {
    z-index: 999;
    width: 100%;
    height: auto;
  }

  #popup-aplayer {
    position: fixed;
    left: 0em;
    bottom: 0em;
  }

  #page-effect {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3000;
  }

  .fade {
    opacity: 1;
    transition: opacity 0.5s;
  }
</style>