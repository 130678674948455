
<template>
    <div class="progress">
        <el-progress type="circle" :percentage="0" class="item"></el-progress>
        <!-- <el-progress type="circle" :percentage="25"></el-progress>
        <el-progress type="circle" :percentage="100" status="success"></el-progress>
        <el-progress type="circle" :percentage="70" status="warning"></el-progress>
        <el-progress type="circle" :percentage="50" status="exception"></el-progress> -->
    </div>
</template>


<script>
    export default {
        name: "Progress",
    }
</script>


<style>
    .progress {
        display: none;
        z-index: 1000;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.2);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .progress .item {
        z-index: 1001;
        position: fixed;
        right: 46vw;
        bottom: 43vh;
    }
</style>