import axios from "axios";
import dayjs from 'dayjs';
import FakeProgress from 'fake-progress';


// 创建axios服务
const request = axios.create({
  baseURL: 'https://proce.chamas.com.cn',   // url = base api url + request url
  withCredentials: false,                   // send cookies when cross-domain requests
  timeout: 1000*12                          // request timeout
})

// 所有请求头
// axios.defaults.headers["Content-Type"]= "application/x-www-form-urlencoded";

// 重试次数和重试延迟
const MAX_RETRIES = 5;
const RETRY_DELAY = 1000;

// 响应拦截器
request.interceptors.response.use(
  response => {
    // 如果返回的状态码为2xx，说明接口请求成功，可以正常拿到数据，否则的话抛出错误
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    const config = error.config;
    // 如果config不存在或未设置重试选项，请拒绝
    if (!config || !config.retry) return Promise.reject(error);

    // 设置变量跟踪重试次数
    config.__retryCount = config.__retryCount || 0;

    // 检查是否已经把重试次数用完
    if (config.__retryCount >= MAX_RETRIES) {
      return Promise.reject(error);
    }

    // 增加重试次数
    config.__retryCount += 1;

    // 创建新的Promise来处理指数退避
    const backoff = new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, RETRY_DELAY || 1);
    });

    // 返回调用axios来重试请求的Promise
    return backoff.then(() => {
      return request(config);
    });
  }
);


// 判断时间是否有效
const dateValid = (date)=> {
    if(! date) return;
    date = new Date(date);
    return date instanceof Date && !isNaN(date.getTime());
}

// 数组去重
const arraySole = (array)=> {
    if(Object.prototype.toString.call(array) !='[object Array]') return;
    return Array.from(new Set(array));
}

// 数组洗牌乱序
const arrayShuffle = (array)=> {
    if(Object.prototype.toString.call(array) !='[object Array]') return;
    let len = array.length;
    let index, temp;
    while(len > 0){
        index = Math.floor(Math.random() * len);
        temp = array[len - 1];
        array[len - 1] = array[index];
        array[index] = temp;
        len --;
    }
    return array;
}

// 防抖函数
const debounce = (callback, wait = 0)=> {

  if (typeof callback !== 'function') return;
  let timeid = null;
  let result;

  return ()=> {
    let context = this;
    let args = arguments;
    if (timeid) clearTimeout(timeid);
    timeid = setTimeout(function () {
      result = callback.apply(context, args);
    }, wait);
    return result;
  }
}

// 取随机整数
const getRandomInt = (min, max)=> {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// 渲染优化
const defer = (ref, maxFrameCount = 1000)=> {
  const frameCount = ref(0);
  const refreshFrameCount = ()=> {
    requestAnimationFrame( ()=> {
      frameCount.value ++;
      if(frameCount.value < maxFrameCount){
        refreshFrameCount();
      }
    })
  }
  refreshFrameCount();
  return function (showInFrameCount){
    return frameCount.value >= showInFrameCount;
  };
}

// 进度函数
const fake = (time = 10000, immediate = true)=> {
  return new FakeProgress({
    TimeConstant: time,
    autoStart: immediate
  })
}


// 初始化store数据

const handleArticle = (data) => {
  const articleList = data.map((item) => {
    const tags = item.tags ? item.tags.split(";") : [];
    return {
      uuid: item.uuid,
      title: item.title,
      route: item.route,
      cover: item.cover,
      background: item.background,
      recommend: item.recommend,
      encryption: item.encryption,
      introduce: item.introduce,
      attribute: {
        classify: item.classify,
        label: item.label,
        tags: tags.length === 1 && tags[0] === "" ? [] : tags,
      },
      author: item.author,
      dynamic: {
        push: item.push,
        update: item.update,
        views: item.views,
      },
    };
  });

  return articleList.sort((a, b) => {
    const aTime = dateValid(a.dynamic.push) ? dayjs(a.dynamic.push.toString().length === 10 ? a.dynamic.push*1000 : a.dynamic.push) : dayjs().subtract(1, "hour");
    const bTime = dateValid(b.dynamic.push) ? dayjs(b.dynamic.push.toString().length === 10 ? b.dynamic.push*1000 : b.dynamic.push) : dayjs().subtract(2, "hour");
    return bTime.diff(aTime, "minute");
  });
};

const handleAuthor = (data) => {
  return data.map(({ uuid, name, head, address }) => ({ uuid, name, head, address }));
}

const handleWitticism = (data) => {
  return data.reduce((witticism, item) => {
    const group = item.group || 'default';
    if (!witticism[group]) witticism[group] = [];
    witticism[group].push({
      content: item.content,
      origin: item.origin
    });
    return witticism;
  }, {});
}

const handleAtlas = (data, title, describe) => {
  return {
    cover: data[Math.floor(Math.random() * data.length)].route,
    title: title,
    describe: describe,
    amount: data.length
  };
}

const handlePlayMusic = (data) => {
  return data.map(({ artist, title: name, audio: url, cover, lyric: lrc }) => ({ artist, name, url, cover, lrc }));
}


export {
  request,
  dateValid,
  arraySole,
  arrayShuffle,
  debounce,
  getRandomInt,
  defer,
  fake,

  handleArticle,
  handleAuthor,
  handleWitticism,
  handleAtlas,
  handlePlayMusic
};