import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from './store/';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import '@/assets/iconfont1.css';
import '@/assets/iconfont2.css';
import VueRouter from 'vue-router';
import 'element-ui/lib/theme-chalk/index.css';
import {Input,Button,Col,Dropdown,DropdownMenu,DropdownItem,Progress,
       Dialog,Divider,Image,Avatar, Timeline, TimelineItem, Badge, Popover, Collapse, CollapseItem, Upload, Notification} from 'element-ui';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Viewer);
Viewer.setDefaults({
  title: false,
  toolbar: false
})

Vue.use(Input);
Vue.use(Button);
Vue.use(Col);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Progress);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Avatar);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Badge);
Vue.use(Popover);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Upload);
Vue.prototype.$notify = Notification;

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')