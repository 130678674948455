
<template>
    <div>
        <div class="asia-headline">
            <i class="iconfont icon-guidang"></i>
            文章目录
        </div>
        <div class="catalogue">
            <el-collapse accordion>
                <el-collapse-item v-for="(catalogue, index) in catalogueFomat" :class="catalogueClass(catalogue)" @click.native="jump(catalogue, true)"
                :key="catalogue.val.id" :title="`${index + 1}.${catalogue.val.title}`" :name="catalogue.val.id">
                    <template v-if="catalogue.children && catalogue.children.length">
                        <div v-for="(children, serial) in catalogue.children" :key="children.id" @click="jump(children.id, false)">
                            {{index + 1}}.{{serial + 1}} {{children.title}}
                        </div>
                    </template>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>


<script>
    export default {
        name: "Catalogue",
        props: ['catalogue'],
        methods: {
            catalogueClass(catalogue){
                if(catalogue.children && catalogue.children.length) return;
                return "without";
            },
            jump(element, mode){
                if(mode){
                    if(element.children && element.children.length) return;
                    document.getElementById(element.val.id).scrollIntoView();
                }else{
                    document.getElementById(element).scrollIntoView();
                }
            }
        },
        computed: {
            catalogueFomat(){
                if(! this.catalogue || ! this.catalogue.length) return;
                return this.catalogue;
            }
        }
    }
</script>


<style scoped>
    .asia-headline, .catalogue {
        padding-left: 10%;
        padding-right: 10%;
        width: 80%;
    }
    .asia-headline {
        padding-top: 1em;
        font-size: 1.2em;
        font-family: "华文楷体";
        padding-bottom: .2em;
    }
    .catalogue {
        padding-top: .2em;
        padding-bottom: 1em;
        font-family: "华文楷体";
    }
</style>

<style>
    .catalogue .el-collapse {
        border-top: none;
        border-bottom: none;
    }
    .catalogue .without .el-collapse-item__content {
        display: none;
    }
    .catalogue .without i {
        display: none;
    }
    .catalogue .el-collapse-item__header {
        height: 2em;
        overflow: hidden;
        line-height: 2em;
        font-size: 1.01em;
        white-space: nowrap;
        border-bottom: none;
        font-family: "华文楷体";
        background: rgba(255, 255, 255, .0);
    }

    .catalogue .el-collapse-item__wrap {
        border-bottom: none;
        background: rgba(255, 255, 255, .0);
    }

    .catalogue .el-collapse-item__content {
        cursor: pointer;
        overflow: hidden;
        font-size: 1.01em;
        margin-left: .8em;
        white-space: nowrap;
        padding-bottom: .25em;
        font-family: "华文楷体";
        background: rgba(255, 255, 255, .0);
    }
</style>