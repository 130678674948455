
<template>
  <div class="subject-aplayer"><div ref="aplayer"></div></div>
</template>


<script>
  import APlayer from 'aplayer';
  import {mapGetters} from 'vuex'
  import 'aplayer/dist/APlayer.min.css';
  import MediaContent from '@/store/Media';
  export default {
    name: "AplayerMini",
    data() {
      return {
          volume: 0.8
      }
    },
    methods: {
      initAPlayer(){
        MediaContent.state.aplayer = new APlayer({
          lrcType: 3,
          loop: 'all',
          fixed: true,
          mutex: true,
          order: 'list',
          preload: 'auto',
          autoplay: false,
          listFolded: false,
          volume: this.volume,
          audio: this.musicPlayList,
          element: this.$refs.aplayer,
        });

        MediaContent.state.aplayer.on('play', () => {
          this.$store.commit('media/SET_PLAY_MUSIC_INDEX', MediaContent.state.aplayer.list.index);
        });
      }
    },
    computed: {
      ...mapGetters('media', ['musicPlayIndex', 'musicPlayList']),
      shouldShowPlayer() {return this.musicPlayIndex != null && this.musicPlayList.length > 0;}
    },
    watch: {
      "shouldShowPlayer": {
        immediate: true,
        handler(val, old){
            if (val && val != old) {
              if (MediaContent.state.aplayer != null) {
                MediaContent.state.aplayer.destroy();
                MediaContent.state.aplayer = null;
              }
              this.$nextTick(() => {this.initAPlayer();})
            }
        } 

      }
    }
  }
</script>


<style>
  /* 整个aplayer框体 */
  .aplayer.aplayer-fixed {
    max-width: 420px !important;
  }
  /* aplayer播放框体 */
  .aplayer.aplayer-fixed .aplayer-body {
    max-width: 402px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    background: rgba(255, 255, 255, .1) !important;
  }
  /* aplayer播放框体右侧展开关闭按钮 */
  .aplayer .aplayer-miniswitcher {
    width: 18px !important;
  }
  /* aplayer播放框mini形态 */
  .aplayer.aplayer-narrow .aplayer-body {
    left: -66px !important;
  }

  /* info信息框体 */
  .aplayer .aplayer-info .aplayer-controller {
    width: 52% !important;
  }
  /* 进度框 */
  .aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar {
    height: 2px !important;
  }
  /* 所有按钮框体 */
  .aplayer .aplayer-info .aplayer-controller .aplayer-time {
    left: 300px !important;
    position: absolute !important;
  }
  /* 时间进度数值 */
  .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-time-inner {
    left: -128px !important;
    bottom: -5px !important;
    position: absolute !important;
  }
  /* 音量控制标 */
  .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap {
    right: 48px !important;
    bottom: -5px !important;
    position: absolute !important;
  }

  /* 随机、循环播放、歌词控制图标 */
  .aplayer.aplayer-arrow .aplayer-icon-loop, .aplayer.aplayer-arrow .aplayer-icon-order {
    display: block !important;
  }
  .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-loop {
    right: 25px !important;
    bottom: -5px !important;
    position: absolute !important;
  }
  .aplayer .aplayer-icon-lrc {
    right: -15px !important;
    bottom: -5px !important;
    position: absolute !important;
  }
  /* 单独控制每个坐标 */
  .aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-order {
    right: 7px !important;
    bottom: -5px !important;
    position: absolute !important;
  }
  .aplayer.aplayer-fixed .aplayer-icon-back, .aplayer.aplayer-fixed .aplayer-icon-forward,
  .aplayer.aplayer-fixed .aplayer-icon-menu, .aplayer.aplayer-fixed .aplayer-icon-play {
    bottom: 18px !important;
    position: absolute !important;
  }
  .aplayer.aplayer-withlist .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-menu {
    left: 3.5px !important;
  }
  .aplayer.aplayer-fixed .aplayer-icon-forward {
    right: 8px !important;
  }
  .aplayer.aplayer-fixed .aplayer-icon-play {
    right: 28px !important;
  }
  .aplayer.aplayer-fixed .aplayer-icon-back {
    right: 48px !important;
  }
  

  /* 自定义样式 */
  .aplayer {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    background: rgba(255, 255, 255, .85) !important;
  }
  .aplayer .aplayer-pic {
    opacity: .6 !important;
    width: 65px !important;
    height: 65px !important;
    display: block !important;
    background-color: rgba(255, 255, 255, .85) !important;
  }
  .aplayer .aplayer-info .aplayer-music .aplayer-title {
    font-size: 20px !important;
    font-family: "华文楷体" !important;
  }
  .aplayer .aplayer-info .aplayer-music .aplayer-author {
    font-size: 15px !important;
    font-family: "华文楷体" !important;
  }
  .aplayer .aplayer-lrc::before, .aplayer .aplayer-lrc::after {
    height: 0px !important;
  }
  .aplayer .aplayer-lrc {
    pointer-events: none !important;
    text-shadow: -1px -1px 0 #fff !important;
  }
  .aplayer .aplayer-lrc .aplayer-lrc-contents {
    font-size: 15px !important;
    font-family: "华文楷体" !important;
  }
  .aplayer .aplayer-lrc .aplayer-lrc-contents .aplayer-lrc-current {
      font-size: 1.02em;
      color: red !important;
      font-weight: 1000 !important;
  }
  .aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar .aplayer-volume {
    background: rgba(88, 188, 255, .5) !important;
  }
  .aplayer.aplayer-withlist .aplayer-list ol {
    max-width: 420px !important;
  }
  .aplayer.aplayer-withlist .aplayer-list ol li .aplayer-list-title {
    font-size: 1.28em !important;
    font-family: "华文楷体" !important;
    color: black !important;
  }
  .aplayer.aplayer-withlist .aplayer-list ol li .aplayer-list-author {
    font-size: 1.18em !important;
    font-family: "华文楷体" !important;
  }
</style>