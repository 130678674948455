
<template>
    <div class="levitation" v-show="levitation_show">
        <i class="iconfont icon-huidaodingbu_huaban" @click="toTop"></i>
    </div>
</template>


<script>
    import {mapState, mapMutations} from 'vuex';
    export default {
        name: "Levitation",
        props: ["BackTop"],
        methods: {
            ...mapMutations('popup', {levitationShow: 'LEVITATION_SHOW'}),
            toTop(){
                this.BackTop();
                this.levitationShow();
            }
        },
        computed: {
            ...mapState('popup', ['levitation_show']),
        }
    }
</script>


<style scoped>
    .levitation {
        position: fixed;
        right: 1vw;
        bottom: 2.5vh;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .levitation:hover {
        transform: scale(1.2);
        transition: all 0.3s ease;
    }
    .levitation .iconfont {
        font-size: 30px;
        cursor: pointer;
    }

    .fly {
        animation: fly 1.8s;
    }

    .levitation-show {
        animation:  show .5s;
    }
    .levitation-hide {
        animation:  hide .5s;
    }

    @keyframes fly {
        0% {
            transform: translateY(0vh);
        }
        100% {
            transform: translateY(-97.5vh);
        }
    }
    @-webkit-keyframes fly {
        0% {
            transform: translateY(0vh);
        }
        100% {
            transform: translateY(-97.5vh);
        }
    }

    @keyframes hide {
        0% {
            display: block;
            transform: translateX(0vw);
        }
        100% {
            transform: translateX(1vw);
            display: none;
        }
    }
    @-webkit-keyframes hide {
        0% {
            display: block;
            transform: translateX(0vw);
        }
        100% {
            transform: translateX(1vw);
            display: none;
        }
    }

    @keyframes show {
        0% {
            display: none;
            transform: translateX(0vw);
        }
        100% {
            transform: translateX(-1vw);
            display: block;
        }
    }
    @-webkit-keyframes show {
        0% {
            display: none;
            transform: translateX(0vw);
        }
        100% {
            transform: translateX(-1vw);
            display: block;
        }
    }
</style>