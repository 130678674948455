
<template>
    <div id="music">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span">音乐</div>
        </div>
        <div id="layout-content">
            <div class="layout">
                <div class="music-contents">
                    <div class="music-label">
                        <div class="label-value copyright-notice">
                            <span>{{copyright_notify}}</span>
                        </div>
                        <div class="label-value label-left" v-if="option_value !== '正在播放' && option_value !== '站长收藏'">
                            <el-col :span="24">
                                <el-dropdown class="left-el-dropdown" trigger="click" @command="handleChangeSubset">
                                    <span class="el-dropdown-link">
                                        {{ subset_value }}
                                        <i class="el-icon-arrow-down el-icon--right i-second"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="subset in subsets" :key="subset" :command="subset" v-if="subset_value !== subset" >
                                            {{ subset }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-col>
                        </div>
                        <div class="label-value label-right">
                            <el-col :span="24">
                                <el-dropdown class="right-el-dropdown" trigger="click" @command="handleChangeOption">
                                    <span class="el-dropdown-link">
                                        {{ option_value }}
                                        <i class="el-icon-arrow-down el-icon--right i-second"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="option in options" :key="option" :command="option" v-if="option_value !== option" >
                                            {{ option }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-col>
                        </div>
                    </div>
                    <div class="music-board">
                        <div class="music-container" v-for="(music, index) in music_list" :key="index" :class="{'playing': isPlaying(index)}">
                            <div class="music-content">
                                <span class="music-serial">{{ index + 1 }}</span>
                                <div class="music-cover">
                                    <el-image :src="music.cover">
                                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                    </el-image>
                                </div>
                                <div class="music-song">
                                    <span class="music-title">{{ music.name }}</span>
                                     - 
                                     <span class="music-artist">{{ music.artist }}</span>
                                </div>
                                <div class="music-operate">
                                    <span class="iconfont icon-bofang" title="立即播放" @click="handlePlay(index)"></span>
                                    <span class="iconfont icon-tianjia" title="加入待播" v-if="option_value !== '正在播放'" @click="handleAdd(music)"></span>
                                    <span class="iconfont icon-shanchu" title="移出列表" v-if="option_value == '正在播放'" @click="handleRemove(index)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {handlePlayMusic} from '@/Tools';
    import Background from '@/components/overall/Background.vue';
    import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
    export default {
        name: "Music",
        props: ['BackTop'],
        components: {Background},
        data(){
            return {
                copyright_notify: "注意：站内歌曲数据源于www.kugou.com，仅供学习和分享，请勿作商用！",
                background: null,
                subset_value: null,
                subsets: null,
                option_value: '正在播放',
                options: [ '正在播放', '站长收藏', '个人歌单', '歌手分类', '标签分类', '语言分类'],
                music_list: null,
                handle_music: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
            ...mapActions('media', ['playMusic', 'updateMusicList', 'addMusic', 'removeMusic', 'clearMusic']),
            handleBackground() {
                let background = this.wallpaperRandom();
                if(background){
                    this.background = background;
                    setTimeout( ()=> {this.loadingHide();}, 500)
                }else{
                    initialApi.random_wallpaper().then(
                        response => {
                            if(response.status === 200){
                                this.background = response.data.route;
                            }
                            setTimeout( ()=> {this.loadingHide();}, 500)
                        },
                        error => {
                            this.$notify.error({title: '错误', message: error});
                            setTimeout( ()=> {this.loadingHide();}, 500)
                        }
                    )
                }
            },
            handleChangeOption(command) {
                if (command === this.option_value) return;
                this.loadingShow();
                switch(command) {
                    case '正在播放':
                        this.handle_music = null;
                        this.music_list = this.musicPlayList;
                        break
                    case '站长收藏':
                        let prefer_list = this.musicAppointkey('prefer');
                        this.music_list = handlePlayMusic(prefer_list[1]);
                        break;
                    case '个人歌单':
                        this.handle_music = this.musicAppointkey('group');
                        this.subsets = Object.keys(this.handle_music);
                        this.subset_value = this.subsets[0];
                        this.music_list = handlePlayMusic(this.handle_music[this.subset_value]);
                        break;
                    case '歌手分类':
                        this.handle_music = this.musicAppointkey('artist');
                        this.subsets = Object.keys(this.handle_music);
                        this.subset_value = this.subsets.length > 0 ? this.subsets[0] : null;
                        this.music_list = handlePlayMusic(this.handle_music[this.subset_value]);
                        break;
                    case '标签分类':
                        let label_list = {1: '网络流行', 2: '中国古风', 3: '潮流经典', 4: '影视原声', 5: '纯音乐', 6: '其他类型'};
                        this.handle_music = this.musicAppointkey('label');
                        let labels = Object.keys(this.handle_music);
                        this.subsets = labels.map(label => label_list[label] || '未知标签');
                        this.subset_value = this.subsets.length > 0 ? this.subsets[0] : null;
                        this.music_list = handlePlayMusic(this.handle_music[labels[0]]);
                        break;
                    case '语言分类':
                        let language_list = {1: '中文', 2: '粤语', 3: '英语', 4: '日语', 5: '韩语', 6: '其他'};
                        this.handle_music = this.musicAppointkey('language');
                        let languages = Object.keys(this.handle_music);
                        this.subsets = languages.map(language => language_list[language] || '未知语言');
                        this.subset_value = this.subsets.length > 0? this.subsets[0] : null;
                        this.music_list = handlePlayMusic(this.handle_music[languages[0]]);
                        break;
                }

                this.option_value = command;
                this.handleBackground();
            },
            handleChangeSubset(command){
                if (this.option_value === '正在播放' || this.option_value === '站长收藏') return;
                if (command === this.subset_value) return;
                this.loadingShow();
                
                if (this.option_value === '个人歌单' || this.option_value === '歌手分类') {
                    const subset = this.subsets.find(subset => subset === command);
                    if (subset) {this.music_list = handlePlayMusic(this.handle_music[subset]);}
                }

                if (this.option_value === '标签分类') {
                    let label_list = {1: '网络流行', 2: '中国古风', 3: '潮流经典', 4: '影视原声', 5: '纯音乐', 6: '其他类型'};
                    const key = Object.keys(label_list).find(key => label_list[key] === command);
                    if (key) {this.music_list = handlePlayMusic(this.handle_music[key]);}
                }

                if (this.option_value === '语言分类') {
                    let language_list = {1: '中文', 2: '粤语', 3: '英语', 4: '日语', 5: '韩语', 6: '其他'};
                    const key = Object.keys(language_list).find(key => language_list[key] === command);
                    if (key) {this.music_list = handlePlayMusic(this.handle_music[key]);}
                }

                this.subset_value = command;
                setTimeout( ()=> {this.loadingHide();}, 300)
                
            },
            handlePlay(index) {
                if (this.option_value !== '正在播放') {this.updateMusicList(this.music_list);}
                this.playMusic(index);
            },
            handleAdd(music) {
                this.addMusic(music);
            },
            handleRemove(index) {
                this.removeMusic(index);
            }
        },
        computed: {
            ...mapState('media', ['aplayer']),
            ...mapGetters('atlas', ['wallpaperRandom']),
            ...mapGetters('media', ['musicPlayIndex', 'musicPlayList', 'musicAppoint', 'musicAppointkey']),
            isPlaying(index) {
                return (index) => {
                    if (this.option_value !== '正在播放') {return false;}
                    return index === this.musicPlayIndex;
                }
            }
        },
        mounted(){
            this.loadingShow();
            this.BackTop();
            this.music_list = this.musicPlayList;
            this.handleBackground();
        }
    }
</script>


<style scoped>
    #music {
        width: 100%;
    }
    #site-title, #layout-content {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }
    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    #layout-content .layout {
        width: 100%;
        margin-top: 2.5em;
        max-width: 1200px;
        position: relative;
        border-radius: 4px;
        background-size: cover !important;
    }
    #layout-content:after {
        height: 0;
        content: "";
        clear: both;
        display: block;
        visibility: hidden;
    }
    .music-contents:after {
        height: 0;
        content: "";
        clear: both;
        display: block;
        visibility: hidden;
    }
    .layout .music-contents {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2.5em;
        border-radius: 8px;
        padding-bottom: 2.5em;
        background: rgba(255, 255, 255, .9);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
    .music-contents .music-label {
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: right;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
    }
    .music-contents .label-value {
        cursor: pointer;
        border-radius: 4px;
        padding-top: .25em;
        padding-left: .5em;
        padding-right: .5em;
        padding-bottom: .25em;
    }
    .label-value.copyright-notice {
        color: red;
        font-size: 1.1em;
        margin-right: 5px;
        font-family: "华文楷体";
        text-shadow: 0.05rem 0.05rem 0.1rem rgb(0 0 0 / 10%);
    }
    .label-value .el-dropdown {
        font-size: 100%;
        cursor: pointer;
        color: #5b5f67;
        font-size: 1.08em;
        text-shadow: 0.05rem 0.05rem 0.1rem rgb(0 0 0 / 10%) !important;

    }
    .el-dropdown-menu {
        left: 1190px !important;
        overflow-y: auto !important;
        max-height: 210px !important;
        scrollbar-width: thin !important;
    }
    .label-value .el-dropdown-menu {
        text-decoration: none;
    }
    .el-dropdown-menu__item {
        color: #67789b !important;
        font-weight: 800 !important;
        font-size: 1.05em !important;
        font-family: "华文楷体" !important;
        text-shadow: 0.05rem 0.05rem 0.1rem rgb(0 0 0 / 20%) !important;
    }
    .label-value .i-second {
        margin-left: -5px;
    }
</style>

<style scoped>
    .music-board {
        display: flex;
        flex-wrap: wrap;
        margin: 1.5em 0;
        justify-content: space-between;
    }
    .music-container {
        position: relative;
        width: calc(50% - 1em);
        transition: border 0.3s ease;
    }
    .music-container:hover {
        align-items: center;
        justify-content: space-between;
        transition: background-color .2s ease;
        background: rgba(255, 255, 255, .7);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
    .playing {
        align-items: center;
        justify-content: space-between;
        transition: background-color .2s ease;
        background: rgba(77, 166, 255, .2);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 3px rgba(0, 0, 0, .04);
    }
    .music-content {
        display: flex;
        padding: .5em;
        height: 2.5em;
        align-items: center;
        justify-content: flex-start;
        transition: background-color .2s ease;
        background: rgba(225, 225, 225, .05);
        box-shadow: 0 2px 0 rgba(180, 180, 180, 0.02), 0 2px 0 rgba(200, 200, 200, 0.28);
    }
    .music-content-playing {
        background: rgba(110, 176, 217, 0.18) !important;
    }
    .music-serial {
        font-size: 18px;
        text-align: right;
        padding-right: 15px;
        color: rgba(102, 102, 102, .4);
    }
    .music-cover {
        width: 10%;
        height: 100%;
        display: flex;
        overflow: hidden;
        margin-left: .5em;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .music-song {
        flex: 1;
        text-align: left;
        margin: 0 1em;
    }
    .music-song .music-artist {
        font-size: 1em;
        font-weight: 600;
        color: rgba(180, 180, 180, .8);
        text-shadow: 0.01rem 0.01rem 0.08rem rgb(0 0 0 / 10%);
    }
    .music-song .music-title {
        font-weight: 800;
        font-size: 1.08em;
        color: rgba(100, 100, 100, .8)
    }
    .music-operate {
        display: flex;
        gap: 0.5em; /* 控制操作图标间距 */
        width: 10%; /* 控制操作区域宽度 */
        justify-content: flex-end; /* 操作区域右对齐 */
    }
    .label-left .iconfont, .music-operate .iconfont {
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 22px !important;
    }
    .label-left .iconfont:hover {
        transform: scale(1.08);
        transition: all 0.3s ease;
    }
    .music-operate .iconfont:hover {
        transform: scale(1.08);
        transition: all 0.3s ease;
    }
</style>