
<template>
    <div class="post">
        <div class="post-cover" :class="postCover">
            <router-link  :to="`/article/${article.title}/`" :class="postCover">
                <el-image :src="article.cover" lazy>
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </router-link>
        </div>
        <div class="post-info" :class="postInfo">
            <div v-if="article.recommend" class="info-recommend" :class="postreCommend">文章推荐</div>
            <div v-if="article.encryption == 2" class="info-encryption" :class="postEncryption">加密文章</div>
            <div class="info-title"><router-link  :to="`/article/${article.title}/`">{{article.title}}</router-link></div>
            <div class="info-introduce">{{article.introduce}}</div>
            <div class="info-dynamic">
                <div class="dynamic-meta">
                    <div class="meta-author">
                        <div class="author-head">
                            <a  :href="author.address" target="_bank"><el-avatar size="small" :src="author.head"></el-avatar></a>
                        </div>
                        <div class="author-name">
                            <a  :href="author.address" target="_bank">{{author.name | authorFomat}}</a>
                        </div>
                    </div>
                    <div class="meta-date">
                        <div class="date-icon"><i class="el-icon-date"></i></div>
                        <div class="date-value">{{article.dynamic.push | pushFomat}}</div>
                    </div>
                    <div class="meta-views">
                        <div class="views-icon"><i class="el-icon-view"></i></div>
                        <div class="views-value">{{article.dynamic.views | viewsFomat}}</div>
                    </div>
                </div>
                <div class="dynamic-classify">
                    <div class="classify-value"><router-link  :to="`/archive/tags/${article.attribute.classify}/`">{{article.attribute.classify | classifyFomat}}</router-link></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import dayjs from 'dayjs';
    export default {
        name: "Post",
        props: ["article", "author"],
        computed: {
            postCover(){
                let className;
                switch(this.article.site){
                    case "cover":
                        className = "cover-left";
                        break;
                    case "info":
                        className = "cover-right";
                        break;
                }
                return className;
            },
            postInfo(){
                let className;
                switch(this.article.site){
                    case "info":
                        className = "info-left";
                        break;
                    case "cover":
                        className = "info-right";
                        break;
                }
                return className;
            },
            postreCommend(){
                let className;
                switch(this.article.site){
                    case "info":
                        className = "recommend-left";
                        break;
                    case "cover":
                        className = "recommend-right";
                        break;
                }
                return className;
            },
            postEncryption(){
                let className;
                switch(this.article.site){
                    case "info":
                        className = "encryption-left";
                        break;
                    case "cover":
                        className = "encryption-right";
                        break;
                }
                return className;
            }
        },
        filters: {
            authorFomat(val){
                if (! val || (! typeof val === "string")){
                    return "作者有误";
                }
                let newVal = val.replace(/(^\s*)|(\s*)$/g,"");
                if(newVal.length > 13){
                    return newVal.slice(0,13);
                }
                return newVal;
            },
            pushFomat(val){
                if (val.toString().length === 10) {
                    return dayjs(val * 1000).format("YYYY-MM-DD");
                } else {
                    return dayjs(val).format("YYYY-MM-DD");
                }
            },
            viewsFomat(val){
                val = parseInt(val);
                if(! /^[0-9]+.?[0-9]*/.test(val)){
                    return 0;
                }
                if(val <= 9999){
                    return val;
                }
                let newVal = val / 10000;
                return newVal.toFixed(1) + "w";
            },
            classifyFomat(val){
                if (! val || (! typeof val === "string")){
                    return "类型有误";
                }
                let newVal = val.replace(/(^\s*)|(\s*)$/g,"");
                if(newVal.length > 4){
                    return newVal.slice(0,4);
                }
                return newVal;
            }
        }
    }
</script>


<style scoped>
    .post {
        width: 100%;
        height: 18em;
        border-radius: 8px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
    .post-cover {
        width: 44%;
        height: 100%;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }

    .post-info {
        width: 56%;
        height: 100%;
        position: relative;
    }

    .cover-left {
        float: left !important;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .cover-right {
        float: right !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .info-left {
        float: left !important;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .info-right {
        float: right !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    a {
        text-decoration: none;
    }
    .post-info::before {
        content: "";
        width: 200px;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        pointer-events: none;
    }
    /* .info-left::before {
        background: -webkit-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: -moz-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: -o-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: -ms-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        left: calc(100% - 98px);
    }
    .info-right::before {
        background: -webkit-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: -moz-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: -o-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: -ms-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.478), #fff, rgba(255,255,255,0.478), rgba(255,255,255,0));
        right: calc(100% - 98px);
    } */
</style>

<style scoped>
    .post-cover .el-image:hover {
        transform: scale(1.15);
    }
    .post-cover a, .el-image, .el-image__inner {
        height: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .post-cover .el-image i {
        font-size: 1.8em;
    }
    .post-cover a {
        color: #585858;
    }

    .info-recommend, .info-encryption {
        z-index: 1;
        position: absolute;
        top: .75em;
        font-size: 1.5em;
        font-family: "华文行楷";
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(150 150 255 / 30%);
        border-radius: 2px;
    }
    .recommend-left, .encryption-left {
        left: .25em;
        -moz-transform:rotate(-18deg);
        -webkit-transform:rotate(-18deg);
    }
    .recommend-right, .encryption-right {
        right: .25em;
        -moz-transform:rotate(18deg);
        -webkit-transform:rotate(18deg);
    }

    .info-recommend {
        color: rgba(255, 80, 80, .5);
        border: 1px solid rgba(255, 100, 100, .5);
    }
    .info-encryption {
        color: rgba(80,80,80,.5);
        border: 1px solid hsla(0,30%,25%,.5);
    }

    .info-title, .info-introduce {
        margin-left: 3%;
        width: 94%;
    }

    .info-title {
        z-index: 2;
        margin-top: 10.3%;
        height: 19%;
        font-size: 1.8em;
        line-height: 1.8;
        font-weight: 800;
        white-space:nowrap;
        overflow: hidden;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
    }
    .info-title a {
        font-family: "华文楷体";
        color: black;
    }
    .info-title a:hover {
        color: #49b1f5;
    }
    .info-introduce {
        z-index: 2;
        height: 28.5%;
        font-family: "华文楷体";
        color: #280000;
        font-size: 1.08em;
        line-height: 1.6;
        font-weight: 300;
        overflow: hidden;
    }
    .info-dynamic {
        z-index: 2;
        margin-left: 3%;
        width: 94%;
        height: 12.5%;
    }

    .dynamic-meta, .dynamic-classify {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .dynamic-meta {
        height: 100%;
        float: left;
    }
    .meta-author, .meta-date, .meta-views {
        height: 100%;
        font-family: "华文仿宋";
        font-size: 1em;
        color: 	#ACACAC;
        float: left;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
    }
    .meta-author {
        margin-right: 1%;
    }
    .meta-date {
        margin-left: 1%;
        margin-right: 1%;
    }
    .meta-views {
        margin-left: 1%;
    }
    .author-name, .views-value {
        max-width: 6em;
    }

    .author-head .el-avatar--small {
        width: 1.5em;
        height: 1.5em;
        line-height: 15px;
        margin-top: 5px;
    }
    .author-name {
        margin-left: 3px;
        overflow: hidden;
        white-space:nowrap;
    }
    .author-name a {
        color: #ACACAC;
    }
    .author-name a:hover {
        color: #49b1f5;
    }
    .date-icon, .views-icon {
        margin-left: 5px;
        text-align: right;
    }
    .date-value, .views-value {
        margin-left: 3px;
        overflow: hidden;
        white-space:nowrap;
    }
    .views-value {
        margin-top: -2px;
    }

    .dynamic-classify {
        height: 100%;
        float: right;
        line-height: 100%;
    }
    .classify-value {
        border: 1px dashed  #00b5ad;
        border-radius: 3px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .12), 0 0 2px rgba(0, 0, 0, .04);
    }
    .classify-value a {
        font-size: 1.08em;
        font-family: "华文行楷";
        color: #00b5ad;
    }
    .classify-value a:hover {
        color: #49b1f5;
    }
</style>