
<template>
    <div>
        <div class="asia-headline">
            随笔
            <i class="iconfont icon-yumaobi"></i>
        </div>
        <div class="witticism">
            <div class="witticism-content">{{witticism ? witticism.content : ""}}</div>
            <div class="witticism-informal">—— {{witticism ? (witticism.origin) : "" | originFomat}}</div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "Witticism",
        props: ["witticism"],
        data(){
            return{

            }
        },
        filters: {
            originFomat(val){
                if(val && (typeof val == "string") &&
                (val.constructor === String) && (Object.prototype.toString.call(val) == "[object String]")){
                    return val;
                }
                return "作者有误";
            }
        }
    }
</script>


<style scoped>
    .asia-headline, .witticism {
        padding-left: 10%;
        padding-right: 10%;
        width: 80%;
    }
    .asia-headline {
        padding-top: 1em;
        font-size: 1.2em;
        font-family: "华文楷体";
        padding-bottom: .2em;
    }
    .witticism {
        padding-top: .2em;
        padding-bottom: 1em;
    }
    .witticism-content {
        font-family: "华文楷体";
        font-size: 1.08em;
        margin-top: .1em;
        margin-bottom: .8em;
    }
    .witticism-informal {
        font-family: "华文行楷";
        font-size: 1.18em;
        text-align: right;
        overflow: hidden;
    }
</style>