
<template>
    <div class="app_bg" :style="bgStyle"></div>
</template>


<script>
    export default {
        name: "Background",
        props: ["background"],
        data() {
            return {
                gradients: [
                    "linear-gradient(to right, #00c6ff, #0072ff)",
                    "linear-gradient(to right, #ff9a9e, #fad0c4)",
                    "linear-gradient(to right, #11998e, #38ef7d)",
                    "linear-gradient(to right, #654ea3, #eaafc8)",
                    "linear-gradient(to right, #ff7e5f, #feb47b)",
                    "linear-gradient(to right, #d4fc79, #96e6a1)"
                ],
                bgStyle: {
                    background: '',
                    backgroundSize: 'cover'
                }
            };
        },
        mounted() {
            // 随机选择一个渐变色作为默认背景
            const randomGradient = this.gradients[Math.floor(Math.random() * this.gradients.length)];
            this.bgStyle.background = `${randomGradient} no-repeat center center fixed`;
        },
        watch: {
            background: {
                immediate: true,
                handler(newBackground) {
                    if (newBackground) {
                        const img = new Image();
                        img.src = newBackground;
                        img.onload = () => {
                            // 当图像加载完成后，更新背景图像样式
                            this.bgStyle = {
                                background: `url(${newBackground}) no-repeat center center fixed`,
                                backgroundSize: 'cover'
                            };
                        };
                    }
                }
            }
        }
    }
</script>


<style>
    .app_bg {
        z-index: -9999;
        position: fixed;
        width: 100%;
        height: 100%;
    }
</style>
