
<template>
    <div>
        <div class="article-signature">
            <div class="signature-info">
                <div>作者：{{authorFomat}}</div>
                <div>发表时间： {{pushFomat}}</div>
                <div>版权声明： 自由转载-非商用-非衍生-保持署名（创作共享3.0许可证）</div>
                <div>公众号转载： 请在文末添加作者公众号二维码</div>
            </div>
            <div class="signature-official">
                <div class="official-img">
                    <el-image :src="getSignature.official" lazy>
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
            </div>
        </div>
        <div class="article-appreciate">
            <el-popover placement="top" trigger="hover">
                <div class="appreciate-content">
                    <div class="appreciate-alipay">
                        <div class="alipay-img">
                            <el-image :src="getSignature.alipay" lazy>
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                        <div class="alipay-title">支付宝</div>
                    </div>
                    <div class="appreciate-wechatpay">
                        <div class="wechatpay-img">
                            <el-image :src="getSignature.wechatpay" lazy>
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                        <div class="wechatpay-title">微信</div>
                    </div>
                </div>
                <el-button slot="reference">赞赏</el-button>
            </el-popover>
        </div>
    </div>
</template>


<script>
    import dayjs from 'dayjs';
    import {mapGetters} from 'vuex';
    import {dateValid} from '@/Tools';
    export default {
        name: "ArticleContentSignature",
        props: ['articleContentSignature'],
        computed: {
            ...mapGetters('author', ['getSignature']),
            authorFomat(){
                if (! this.articleContentSignature.author || (! typeof this.articleContentSignature.author === "string")) return "作者有误";
                let val = this.articleContentSignature.author.replace(/(^\s*)|(\s*)$/g,"");
                if(val.length > 13) return val.slice(0, 13);
                return val;
            },
            pushFomat(){
                if(! this.articleContentSignature.push) return;
                if(! dateValid(this.articleContentSignature.push)) return dayjs().format("YYYY-MM-DD hh:mm:ss");

                const timestamp = this.articleContentSignature.push;
                if (timestamp.toString().length === 10) {
                    return dayjs(timestamp * 1000).format("YYYY-MM-DD hh:mm:ss");
                } else {
                    return dayjs(timestamp).format("YYYY-MM-DD hh:mm:ss");
                }
            }
        }
    }
</script>


/**article-signature */
<style scoped>
    .article-signature {
        width: 100%;
        margin-top: 2em;
        height: 10em;
        border-radius: 4px;
        box-shadow: 0 0 8px 8px rgba(232,237,250,0.6), 0 2px 8px 8px rgba(232,237,250,0.5);
    }

    .signature-info {
        float: left;
        padding-left: .5em;
        padding-top: 1.45em;
        padding-bottom: 1.45em;
    }

    .signature-info div {
        font-family: "华文楷体";
        font-size: 1.18em;
        font-weight: 500;
        line-height: 1.5;
    }

    .signature-official {
        float: right;
        width: 8em;
        height: 10em;
        padding-right: .5em;
    }
    .official-img {
        padding-top: 1em;
        width: 8em;
        height: 8em;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
    .official-img .el-image .el-image__inner {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
    }
</style>

/**article-appreciate */
<style scoped>
    .article-appreciate {
        width: 100%;
        padding-top: 1.5em;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }

    .article-appreciate .el-button {
        font-family: "华文行楷";
        font-size: 18px;
        color: orange;
        padding: 8px 15px;
        background: rgba(220,248,240,0.5);
        box-shadow: 0 0 20px 8px rgba(200,210,240,0.6), 0 2px 5px 5px rgba(220,248,240,0.8);
    }

    .el-popover {
        background: rgba(255, 255, 255, .1) !important;
    }

    .appreciate-alipay, .appreciate-wechatpay {
        width: 8em;
    }
    .appreciate-alipay {
        float: left;
    }
    .appreciate-wechatpay {
        margin-left: .8em;
        float: right;
    }
    .alipay-img, .wechatpay-img, .alipay-title, .wechatpay-title {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }

    .appreciate-alipay .alipay-img, .appreciate-wechatpay .wechatpay-img {
        width: 8em;
        height: 8em;
    }


    .appreciate-alipay .el-image .el-image__inner, .appreciate-wechatpay .el-image .el-image__inner {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .alipay-title, .wechatpay-title {
        font-family: "华文行楷";
        font-size: 15px;
        color: orange;
    }
</style>
