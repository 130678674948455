
<template>
  <div>
    <span class="js-cursor-container"></span>
  </div>
</template>


<script>
  export default {
    name: "SpecialEffects",
    methods:{
      love(){
        ! function (e, t, a) {
          function r() {
            for (var e = 0; e < s.length; e++) s[e].alpha <= 0 ? (t.body.removeChild(s[e].el), s.splice(e, 1)) : (s[
                e].y--, s[e].scale += .004, s[e].alpha -= .013, s[e].el.style.cssText = "left:" + s[e].x +
                "px;top:" + s[e].y + "px;opacity:" + s[e].alpha + ";transform:scale(" + s[e].scale + "," + s[e]
                    .scale + ") rotate(45deg);background:" + s[e].color + ";z-index:99999");
            requestAnimationFrame(r)
          }

          function n() {
            var t = "function" == typeof e.onclick && e.onclick;
            e.onclick = function (e) {
              t && t(), o(e)
            }
          }

          function o(e) {
            var a = t.createElement("div");
            a.className = "heart", s.push({
              el: a,
              x: e.clientX - 5,
              y: e.clientY - 5,
              scale: 1,
              alpha: 1,
              color: c()
            }), t.body.appendChild(a)
          }

          function i(e) {
            var a = t.createElement("style");
            a.type = "text/css";
            try {
              a.appendChild(t.createTextNode(e))
            } catch (t) {
              a.styleSheet.cssText = e
            }
            t.getElementsByTagName("head")[0].appendChild(a)
          }

          function c() {
            return "rgb(" + ~~(255 * Math.random()) + "," + ~~(255 * Math.random()) + "," + ~~(255 * Math
                .random()) + ")"
          }
          var s = [];
          e.requestAnimationFrame = e.requestAnimationFrame || e.webkitRequestAnimationFrame || e
              .mozRequestAnimationFrame || e.oRequestAnimationFrame || e.msRequestAnimationFrame || function (e) {
            setTimeout(e, 1e3 / 60)
          }, i(
              ".heart{width: 10px;height: 10px;position: fixed;background: #f00;transform: rotate(45deg);-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);}.heart:after,.heart:before{content: '';width: inherit;height: inherit;background: inherit;border-radius: 50%;-webkit-border-radius: 50%;-moz-border-radius: 50%;position: fixed;}.heart:after{top: -5px;}.heart:before{left: -5px;}"
          ), n(), r()
        }(window, document);
      },
      note(){
        (function () {
          var a_idx = 0;
          window.onclick = function (event) {
            var a = new Array("富强", "民主", "文明", "和谐", "自由", "平等", "公正", "法治", "爱国", "敬业", "诚信", "友善");

            var heart = document.createElement("b"); //创建b元素
            heart.onselectstart = new Function('event.returnValue=false'); //防止拖动

            document.body.appendChild(heart).innerHTML = a[a_idx]; //将b元素添加到页面上
            a_idx = (a_idx + 1) % a.length;
            heart.style.cssText = "position: fixed;left:-100%;"; //给p元素设置样式

            var f = 16, // 字体大小
                x = event.clientX - f / 2, // 横坐标
                y = event.clientY - f, // 纵坐标
                c = randomColor(), // 随机颜色
                a = 1, // 透明度
                s = 1.2; // 放大缩小

            var timer = setInterval(function () { //添加定时器
              if (a <= 0) {
                document.body.removeChild(heart);
                clearInterval(timer);
              } else {
                heart.style.cssText = "font-size:16px;cursor: default;position: fixed;color:" +
                    c + ";left:" + x + "px;top:" + y + "px;opacity:" + a + ";transform:scale(" +
                    s + ");";

                y--;
                a -= 0.016;
                s += 0.002;
              }
            }, 15)

          }
          // 随机颜色
          function randomColor() {

            return "rgb(" + (~~(Math.random() * 255)) + "," + (~~(Math.random() * 255)) + "," + (~~(Math
                .random() * 255)) + ")";

          }
        }());
      },
      blast(){
        function clickEffect() {
          let balls = [];
          let longPressed = false;
          let longPress;
          let multiplier = 0;
          let width, height;
          let origin;
          let normal;
          let ctx;
          const colours = ["#F73859", "#14FFEC", "#00E0FF", "#FF99FE", "#FAF15D"];
          const canvas = document.createElement("canvas");
          document.body.appendChild(canvas);
          canvas.setAttribute("style", "width: 100%; height: 100%; top: 0; left: 0; z-index: 99999; position: fixed; pointer-events: none;");
          const pointer = document.createElement("span");
          pointer.classList.add("pointer");
          document.body.appendChild(pointer);

          if (canvas.getContext && window.addEventListener) {
            ctx = canvas.getContext("2d");
            updateSize();
            window.addEventListener('resize', updateSize, false);
            loop();
            window.addEventListener("mousedown", function(e) {
              pushBalls(randBetween(10, 20), e.clientX, e.clientY);
              document.body.classList.add("is-pressed");
              longPress = setTimeout(function(){
                document.body.classList.add("is-longpress");
                longPressed = true;
              }, 500);
            }, false);
            window.addEventListener("mouseup", function(e) {
              clearInterval(longPress);
              if (longPressed == true) {
                document.body.classList.remove("is-longpress");
                pushBalls(randBetween(50 + Math.ceil(multiplier), 100 + Math.ceil(multiplier)), e.clientX, e.clientY);
                longPressed = false;
              }
              document.body.classList.remove("is-pressed");
            }, false);
            window.addEventListener("mousemove", function(e) {
              let x = e.clientX;
              let y = e.clientY;
              pointer.style.top = y + "px";
              pointer.style.left = x + "px";
            }, false);
          } else {
            console.log("canvas or addEventListener is unsupported!");
          }


          function updateSize() {
            canvas.width = window.innerWidth * 2;
            canvas.height = window.innerHeight * 2;
            canvas.style.width = window.innerWidth + 'px';
            canvas.style.height = window.innerHeight + 'px';
            ctx.scale(2, 2);
            width = (canvas.width = window.innerWidth);
            height = (canvas.height = window.innerHeight);
            origin = {
              x: width / 2,
              y: height / 2
            };
            normal = {
              x: width / 2,
              y: height / 2
            };
          }
          class Ball {
            constructor(x = origin.x, y = origin.y) {
              this.x = x;
              this.y = y;
              this.angle = Math.PI * 2 * Math.random();
              if (longPressed == true) {
                this.multiplier = randBetween(14 + multiplier, 15 + multiplier);
              } else {
                this.multiplier = randBetween(6, 12);
              }
              this.vx = (this.multiplier + Math.random() * 0.5) * Math.cos(this.angle);
              this.vy = (this.multiplier + Math.random() * 0.5) * Math.sin(this.angle);
              this.r = randBetween(8, 12) + 3 * Math.random();
              this.color = colours[Math.floor(Math.random() * colours.length)];
            }
            update() {
              this.x += this.vx - normal.x;
              this.y += this.vy - normal.y;
              normal.x = -2 / window.innerWidth * Math.sin(this.angle);
              normal.y = -2 / window.innerHeight * Math.cos(this.angle);
              this.r -= 0.3;
              this.vx *= 0.9;
              this.vy *= 0.9;
            }
          }

          function pushBalls(count = 1, x = origin.x, y = origin.y) {
            for (let i = 0; i < count; i++) {
              balls.push(new Ball(x, y));
            }
          }

          function randBetween(min, max) {
            return Math.floor(Math.random() * max) + min;
          }

          function loop() {
            ctx.fillStyle = "rgba(255, 255, 255, 0)";
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < balls.length; i++) {
              let b = balls[i];
              if (b.r < 0) continue;
              ctx.fillStyle = b.color;
              ctx.beginPath();
              ctx.arc(b.x, b.y, b.r, 0, Math.PI * 2, false);
              ctx.fill();
              b.update();
            }
            if (longPressed == true) {
              multiplier += 0.2;
            } else if (!longPressed && multiplier >= 0) {
              multiplier -= 0.4;
            }
            removeBall();
            requestAnimationFrame(loop);
          }

          function removeBall() {
            for (let i = 0; i < balls.length; i++) {
              let b = balls[i];
              if (b.x + b.r < 0 || b.x - b.r > width || b.y + b.r < 0 || b.y - b.r > height || b.r < 0) {
                balls.splice(i, 1);
              }
            }
          }
        }
        clickEffect();//调用特效函数
      },
      stars(){
        (function fairyDustCursor() {

          var possibleColors = ["#D61C59", "#E7D84B", "#1B8798"]
          var width = window.innerWidth;
          var height = window.innerHeight;
          var cursor = { x: width / 2, y: width / 2 };
          var particles = [];

          function init() {
            bindEvents();
            loop();
          }

          function bindEvents() {
            document.addEventListener('mousemove', onMouseMove);
            window.addEventListener('resize', onWindowResize);
          }

          function onWindowResize(e) {
            width = window.innerWidth;
            height = window.innerHeight;
          }

          function onMouseMove(e) {
            cursor.x = e.clientX;
            cursor.y = e.clientY;

            addParticle(cursor.x, cursor.y, possibleColors[Math.floor(Math.random() * possibleColors.length)]);
          }

          function addParticle(x, y, color) {
            var particle = new Particle();
            particle.init(x, y, color);
            particles.push(particle);
          }

          function updateParticles() {

            for (var i = 0; i < particles.length; i++) {
              particles[i].update();
            }

            for (var i = particles.length - 1; i >= 0; i--) {
              if (particles[i].lifeSpan < 0) {
                particles[i].die();
                particles.splice(i, 1);
              }
            }

          }

          function loop() {
            requestAnimationFrame(loop);
            updateParticles();
          }

          function Particle() {

            this.character = "*";
            this.lifeSpan = 120; //ms
            this.initialStyles = {
              "position": "fixed",
              "display": "inline-block",
              "top": "0px",
              "left": "0px",
              "pointerEvents": "none",
              "touch-action": "none",
              "z-index": "10000000",
              "fontSize": "25px",
              "will-change": "transform"
            };

            // Init, and set properties
            this.init = function (x, y, color) {

              this.velocity = {
                x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 2),
                y: 1
              };

              this.position = { x: x + 10, y: y + 10 };
              this.initialStyles.color = color;

              this.element = document.createElement('span');
              this.element.innerHTML = this.character;
              applyProperties(this.element, this.initialStyles);
              this.update();

              document.querySelector('.js-cursor-container').appendChild(this.element);
            };

            this.update = function () {
              this.position.x += this.velocity.x;
              this.position.y += this.velocity.y;
              this.lifeSpan--;

              this.element.style.transform = "translate3d(" + this.position.x + "px," + this.position.y + "px, 0) scale(" + (this.lifeSpan / 120) + ")";
            }

            this.die = function () {
              this.element.parentNode.removeChild(this.element);
            }

          }

          function applyProperties(target, properties) {
            for (var key in properties) {
              target.style[key] = properties[key];
            }
          }

          if (!('ontouchstart' in window || navigator.msMaxTouchPoints)) init();
        })();
      },
      scatter(){
        class Circle {
          constructor({ origin, speed, color, angle, context }) {
            this.origin = origin
            this.position = { ...this.origin }
            this.color = color
            this.speed = speed
            this.angle = angle
            this.context = context
            this.renderCount = 0
          }

          draw() {
            this.context.fillStyle = this.color
            this.context.beginPath()
            this.context.arc(this.position.x, this.position.y, 2, 0, Math.PI * 2)
            this.context.fill()
          }

          move() {
            this.position.x = (Math.sin(this.angle) * this.speed) + this.position.x
            this.position.y = (Math.cos(this.angle) * this.speed) + this.position.y + (this.renderCount * 0.3)
            this.renderCount++
          }
        }

        class Boom {
          constructor({ origin, context, circleCount = 10, area }) {
            this.origin = origin
            this.context = context
            this.circleCount = circleCount
            this.area = area
            this.stop = false
            this.circles = []
          }

          randomArray(range) {
            const length = range.length
            const randomIndex = Math.floor(length * Math.random())
            return range[randomIndex]
          }

          randomColor() {
            const range = ['8', '9', 'A', 'B', 'C', 'D', 'E', 'F']
            return '#' + this.randomArray(range) + this.randomArray(range) + this.randomArray(range) + this.randomArray(range) + this.randomArray(range) + this.randomArray(range)
          }

          randomRange(start, end) {
            return (end - start) * Math.random() + start
          }

          init() {
            for (let i = 0; i < this.circleCount; i++) {
              const circle = new Circle({
                context: this.context,
                origin: this.origin,
                color: this.randomColor(),
                angle: this.randomRange(Math.PI - 1, Math.PI + 1),
                speed: this.randomRange(1, 6)
              })
              this.circles.push(circle)
            }
          }

          move() {
            this.circles.forEach((circle, index) => {
              if (circle.position.x > this.area.width || circle.position.y > this.area.height) {
                return this.circles.splice(index, 1)
              }
              circle.move()
            })
            if (this.circles.length == 0) {
              this.stop = true
            }
          }

          draw() {
            this.circles.forEach(circle => circle.draw())
          }
        }

        class CursorSpecialEffects {
          constructor() {
            this.computerCanvas = document.createElement('canvas')
            this.renderCanvas = document.createElement('canvas')

            this.computerContext = this.computerCanvas.getContext('2d')
            this.renderContext = this.renderCanvas.getContext('2d')

            this.globalWidth = window.innerWidth
            this.globalHeight = window.innerHeight

            this.booms = []
            this.running = false
          }

          handleMouseDown(e) {
            const boom = new Boom({
              origin: { x: e.clientX, y: e.clientY },
              context: this.computerContext,
              area: {
                width: this.globalWidth,
                height: this.globalHeight
              }
            })
            boom.init()
            this.booms.push(boom)
            this.running || this.run()
          }

          handlePageHide() {
            this.booms = []
            this.running = false
          }

          init() {
            const style = this.renderCanvas.style
            style.position = 'fixed'
            style.top = style.left = 0
            style.zIndex = '999999999999999999999999999999999999999999'
            style.pointerEvents = 'none'

            style.width = this.renderCanvas.width = this.computerCanvas.width = this.globalWidth
            style.height = this.renderCanvas.height = this.computerCanvas.height = this.globalHeight

            document.body.append(this.renderCanvas)

            window.addEventListener('mousedown', this.handleMouseDown.bind(this))
            window.addEventListener('pagehide', this.handlePageHide.bind(this))
          }

          run() {
            this.running = true
            if (this.booms.length == 0) {
              return this.running = false
            }

            requestAnimationFrame(this.run.bind(this))

            this.computerContext.clearRect(0, 0, this.globalWidth, this.globalHeight)
            this.renderContext.clearRect(0, 0, this.globalWidth, this.globalHeight)

            this.booms.forEach((boom, index) => {
              if (boom.stop) {
                return this.booms.splice(index, 1)
              }
              boom.move()
              boom.draw()
            })
            this.renderContext.drawImage(this.computerCanvas, 0, 0, this.globalWidth, this.globalHeight)
          }
        }

        const cursorSpecialEffects = new CursorSpecialEffects()
        cursorSpecialEffects.init()
      },
      reticular(){
        !function () {
          function n(n, e, t) {
            return n.getAttribute(e) || t
          }

          function e(n) {
            return document.getElementsByTagName(n)
          }

          function t() {
            var t = e("script"), o = t.length, i = t[o - 1];
            return {l: o, z: n(i, "zIndex", -1), o: n(i, "opacity", .5), c: n(i, "color", "0,0,0"), n: n(i, "count", 99)}
          }

          function o() {
            a = m.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, c = m.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
          }

          function i() {
            r.clearRect(0, 0, a, c);
            var n, e, t, o, m, l;
            s.forEach(function (i, x) {
              for (i.x += i.xa, i.y += i.ya, i.xa *= i.x > a || i.x < 0 ? -1 : 1, i.ya *= i.y > c || i.y < 0 ? -1 : 1, r.fillRect(i.x - .5, i.y - .5, 1, 1), e = x + 1; e < u.length; e++) n = u[e], null !== n.x && null !== n.y && (o = i.x - n.x, m = i.y - n.y, l = o * o + m * m, l < n.max && (n === y && l >= n.max / 2 && (i.x -= .03 * o, i.y -= .03 * m), t = (n.max - l) / n.max, r.beginPath(), r.lineWidth = t / 2, r.strokeStyle = "rgba(" + d.c + "," + (t + .2) + ")", r.moveTo(i.x, i.y), r.lineTo(n.x, n.y), r.stroke()))
            }), x(i)
          }

          var a, c, u, m = document.createElement("canvas"), d = t(), l = "c_n" + d.l, r = m.getContext("2d"),
              x = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (n) {
                window.setTimeout(n, 1e3 / 45)
              }, w = Math.random, y = {x: null, y: null, max: 2e4};
          m.id = l, m.style.cssText = "position:fixed;top:0;left:0;z-index:" + d.z + ";opacity:" + d.o, e("body")[0].appendChild(m), o(), window.onresize = o, window.onmousemove = function (n) {
            n = n || window.event, y.x = n.clientX, y.y = n.clientY
          }, window.onmouseout = function () {
            y.x = null, y.y = null
          };
          for (var s = [], f = 0; d.n > f; f++) {
            var h = w() * a, g = w() * c, v = 2 * w() - 1, p = 2 * w() - 1;
            s.push({x: h, y: g, xa: v, ya: p, max: 6e3})
          }
          u = s.concat([y]), setTimeout(function () {
            i()
          }, 100)
        }();
      }
    },
    mounted(){
      let specialEffects = ["love", "note", "blast", "stars", "scatter", "reticular"];
      let effect = specialEffects[Math.floor(Math.random() *  (specialEffects.length))];
      switch(effect){
        case "love":
          this.love();
          break;
        case "note":
          this.note();
          break;
        case "blast":
          this.blast();
          break;
        case "stars":
          this.stars();
          break;
        case "scatter":
          this.scatter();
          break;
        case "reticular":
          this.reticular();
          break;
      }
    }
  }
</script>