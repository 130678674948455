
<template>
    <div>
        <div class="article-site" @mouseenter="siteHide" @mouseleave="siteLoad" v-if="siteFomat">
            <router-link :to="`/article/${site.title}/`">
                <div class="site-layer" :class="site_layer_load"></div>
                <div class="site-cover">
                    <el-image :src="site.cover" lazy>
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
                <div class="site-info" :class="site_class">
                    <div>{{site_name}}一篇</div>
                    <div>{{site.title}}</div>
                </div>
            </router-link>
        </div>
        <div class="article-related" v-if="relatedFormat">
            <div class="related-header">
                <i class="iconfont icon-dianzan"></i>相关推荐
            </div>
            <div class="related-post">
                <div class="post-related" v-for="(post, index) in related" :key="index"
                :class="relatedClass(index)" @mouseenter="relatedHide(index)" @mouseleave="relatedLoad(index)">
                    <router-link :to="`/article/${post.title}/`">
                        <div class="related-layer" :class="index | relatedFomat(that)"></div>
                        <div class="related-cover">
                            <el-image :src="post.cover" lazy>
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                        <div class="related-info">
                            <div class="info-push">{{post.dynamic.push | pushFomat}}</div>
                            <div class="info-title">{{post.title}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import dayjs from 'dayjs';
    import {mapGetters} from 'vuex';
    import {dateValid} from '@/Tools';
    export default {
        name: "ArticleContentRelation",
        props: ['articleContentRelation'],
        data(){
            return{
                that: this,
                site: null,
                related: null,
                site_name: null,
                site_class: null,
                site_layer_load: "layer-load",
                related_layer_load_1: "layer-load",
                related_layer_load_2: "layer-load",
                related_layer_load_3: "layer-load"
            }
        },
        computed: {
            ...mapGetters('article', ['getSiteArticle', 'getRelatedArticle']),
            siteFomat(){
                if(! this.articleContentRelation) return;
                let result = this.getSiteArticle(this.articleContentRelation.title);
                if(result){
                    this.site = result.article;
                    if(result.site){
                        this.site_class = "site-right";
                        this.site_name = "下";
                    }else{
                        this.site_class = "site-left";
                        this.site_name = "上";
                    }
                    return true;
                }
            },
            relatedFormat(){
                if(! this.articleContentRelation) return;
                let result = this.getRelatedArticle(this.articleContentRelation);
                if(! result  || !result.length) return;
                if(result.length > 2){
                    this.related = result.slice(0,3);
                }else{
                    this.related = result;
                }
                return true;
            }
        },
        filters: {
            relatedFomat(index, that){
                let className;
                switch(index){
                    case 0:
                        className = that.related_layer_load_1;
                        break;
                    case 1:
                        className = that.related_layer_load_2;
                        break;
                    case 2:
                        className = that.related_layer_load_3;
                        break;
                }
                return className;
            },
            pushFomat(val){
                if(! val) return;
                if(! dateValid(val)) return dayjs().format("YYYY-MM-DD hh:mm:ss");

                if (val.toString().length === 10) {
                    return dayjs(val * 1000).format("YYYY-MM-DD hh:mm:ss");
                } else {
                    return dayjs(val).format("YYYY-MM-DD hh:mm:ss");
                }
            }
        },
        methods: {
            siteLoad(){
                this.site_layer_load = "layer-load";
            },
            siteHide(){
                this.site_layer_load = "layer-hide";
            },
            relatedLoad(index){
                switch(index){
                    case 0:
                        this.related_layer_load_1 = "layer-load";
                        break;
                    case 1:
                        this.related_layer_load_2 = "layer-load";
                        break;
                    case 2:
                        this.related_layer_load_3 = "layer-load";
                        break;
                }
            },
            relatedHide(index){
                switch(index){
                    case 0:
                        this.related_layer_load_1 = "layer-hide";
                        break;
                    case 1:
                        this.related_layer_load_2 = "layer-hide";
                        break;
                    case 2:
                        this.related_layer_load_3 = "layer-hide";
                        break;
                }
            },
            relatedClass(val){
                let index = parseInt(val);
                if(! index || index< 1) return;
                return "related-multiple";
            }
        }
    }
</script>


/**article-site */
<style scoped>
    .article-site {
        margin-top: 2.5em;
        width: 100%;
        height: 10em;
        position: relative;
        border-radius: 4px;
        box-shadow: 0 0 8px 8px rgba(232,237,250,0.6), 0 2px 8px 8px rgba(232,237,250,0.5);
    }
    .article-site a {
        text-decoration: none;
        color: #0E0E0E;
    }
    .site-layer, .related-layer {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 4px;
        pointer-events: none;
    }
    .layer-load {
        background: rgba(0, 0, 0, .5);
    }
    .layer-hide {
        background: rgba(0, 0, 0, .0);
    }
    .site-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
    }
    .site-cover .el-image .el-image__inner {
        width: 100%;
        height: 100%;
        overflow: auto;
        object-fit: cover;
        -o-object-fit: cover;
    }

    .site-cover .el-image:hover {
        transform: scale(1.15);
    }
    .site-info {
        z-index: 2;
        position: absolute;
        height: 30%;
        top: 35%;
        font-family: "华文楷体";
        font-size: 1.18em;
        font-weight: 1000;
        pointer-events: none;
        color: rgba(255, 255, 255, .95);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .site-left {
        left: 5%;
    }
    .site-right {
        right: 5%;
    }

    .site-info div {
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        overflow: hidden;
    }
</style>

/**article-related */
<style scoped>
    .article-related {
        width: 100%;
        height: 12.98em;
        margin-top: 2.5em;
    }
    .related-header {
        font-family: "华文楷体";
        font-size: 1.48em;
        font-weight: 1000;
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .related-header i {
        margin-right: .5em;
    }
    .related-post {
        margin-top: .5em;
    }


    .post-related {
        float: left;
        width: 16.5em;
        height: 10em;
        position: relative;
        border-radius: 4px;
    }
    .related-multiple {
        margin-left: .9em;
    }
    .post-related a {
        text-decoration: none;
        color: #0E0E0E;
    }
    .related-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
    }
    .related-cover .el-image, .related-cover .el-image .el-image__inner {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
    }
    .related-cover .el-image:hover {
        transform: scale(1.15);
    }
    .related-info {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        color: rgba(255, 255, 255, .95);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .related-info div {
        text-align: center;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
    }
    .info-push {
        margin-top: 3.5em;
        font-family: "华文仿宋";
        font-size: 1.02em;
        font-weight: 300;
    }
    .info-title {
        font-family: "华文楷体";
        font-size: 1.18em;
        font-weight: 1000;
    }
</style>