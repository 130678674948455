
<template>
    <div class="classify">
        <div class="classify-title">
            <div class="title-span">{{title}}</div>
        </div>
        <div class="classify-lists">
            <div class="classify-item" v-for="(index, classify) in classifys" :key="classify">
                <i class="iconfont icon-circle classify-icon"/>
                <span class="classify-content">
                    <router-link :to="`/classify/${classify}/`">{{classify}}</router-link>
                </span>
                <span class="classify-count">（{{classifys[classify]}}）</span>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "ClassifyContent",
        data(){
            return{
                title: null,
                classifys: null
            }
        },
        methods: {
            init(){
                let classifies = this.getClassifys;
                if(JSON.stringify(classifies) === "{}") return;
                this.title = "分类 - " + Object.keys(classifies).length;
                this.classifys = classifies;
            }
        },
        computed: {
            ...mapGetters('article', ['getClassifys'])
        },
        mounted(){
            this.init();
        }
    }
</script>


<style scoped>
    .classify {
        width: 90%;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .9);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }

    .classify-title {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }
    .title-span {
        font-size: 2.37em;
        font-family: "华文楷体";
        font-weight: 1000;
        color: black;
    }

    .classify-lists {
        padding-top: 1.75em;
        width: 100%;
    }
    .classify-item {
        padding-top: .25em;
        padding-bottom: .25em;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .classify-icon {
        margin-top: .14em;
        font-weight: 1000;
        color: blue;
    }
    .classify-item .classify-icon:hover {
        color: red;
    }
    .classify-content a {
        font-weight: 500;
        margin-left: .2em;
        font-size: 1.24em;
        font-family: "华文楷体";
        color: black;
        text-decoration: none;
    }

    .classify-item .classify-content a:hover {
        color: #49b1f5;
    }

    .classify-count {
        font-weight: 500;
        margin-left: .2em;
        font-size: .98em;
        font-family: "华文楷体";
        color: black;
    }

</style>