
<template>
    <div>
        <div class="article-comment"  v-if="! verification">

            <div class="comment-header">
                <i class="iconfont icon-wodepinglun"></i>评论
            </div>
            <el-divider></el-divider>

            <template v-if="articleContentComment && ! verification">
                <div class="comment-lists" v-if="articleContentComment && articleContentComment.length">
                    <div class="lists-item" v-for="(comment, index) in articleContentComment" :key="index">

                        <div class="item-subject">
                            <div class="subject-head"><el-avatar size="small" :src="comment.head"></el-avatar></div>
                            <div class="subject-action">
                                <div class="action-name">{{comment.name}}</div>
                                <div class="action-content">{{comment.content}}</div>
                                <div class="action-dynamic">
                                    <div class="dynamic-support" @click="praise(comment.uuid)">
                                        <i class="iconfont icon-dianzan"></i>
                                        赞{{comment.support > 0 ? ("("+comment.support+")") : ""}}
                                    </div>
                                    <div class="dynamic-comment" @click="alloc(comment.uuid)">
                                        <i class="iconfont icon-pinglun1"></i>回复
                                    </div>
                                    <div class="dynamic-date">
                                        <i class="el-icon-date"></i>{{comment.time | dateFormat}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item-reply-lists" v-if="comment.reply && comment.reply.length">
                            <div class="reply-item" v-for="(replyItem, replyIndex) in comment.reply" :key="replyIndex">
                                <div class="reply-head"><el-avatar size="small" :src="replyItem.head"></el-avatar></div>
                                <div class="reply-action">
                                    <div class="reply-name">{{replyItem.name}}</div>
                                    <div class="reply-content">{{replyItem.content}}</div>
                                    <div class="reply-dynamic">
                                        <div class="reply-support"  @click="praise(comment.uuid)">
                                            <i class="iconfont icon-dianzan"></i>
                                            赞{{replyItem.support > 0 ? ("("+replyItem.support+")") : ""}}
                                        </div>
                                        <div class="reply-comment" @click="aite(comment.uuid, replyItem.name)">
                                            <i class="iconfont icon-pinglun1"></i>回复
                                        </div>
                                        <div class="reply-date">
                                            <i class="el-icon-date"></i>{{replyItem.time | dateFormat}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </template>

            <template>
                <div class="comment-edit">
                    <div class="edit-button" v-show="! edit_show" @click="editShow">
                        来都来了，不留点回忆吗
                        <i class="iconfont icon-pinglun"></i>
                    </div>
                    <div class="edit-from" v-show="edit_show">
                        <div class="from-content">
                            <el-input type="textarea" placeholder="请输入评论内容…" ref="content"
                            rows="3" v-model="edit_from.content"></el-input>
                        </div>
                        <div class="from-name">
                            <el-input ref="name"
                                placeholder="输入昵称…"
                                prefix-icon="iconfont icon-about"
                                v-model="edit_from.name">
                            </el-input>
                        </div>
                        <div class="from-mail">
                            <el-input ref="mail"
                                placeholder="输入邮箱地址…"
                                prefix-icon="iconfont icon-mail"
                                v-model="edit_from.mail">
                            </el-input>
                        </div>
                        <div class="from-submit">
                            <el-button type="primary" :disabled="disabled" @click="submit">
                                <i class="iconfont  icon-jixufabu"></i>
                                评论
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>


<script>
    import qs from 'qs';
    import dayjs from 'dayjs';
    import axios from 'axios';
    import {mapMutations} from 'vuex';
    import {dateValid} from '@/Tools';
    export default {
        name: "ArticleContentComment",
        props: ['title', 'verification', 'articleContentComment'],
        data(){
            return{
                edit_show: false,
                edit_from: {
                    content: "",
                    name: "",
                    mail: "",
                    reply: null
                }
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'}),
            editShow(){
                this.edit_show = true;
                this.$nextTick( ()=> {
                    this.$refs.content.focus();
                })
            },
            praise(uuid){
                if(! uuid) this.$notify.error({title: '错误', message: '请勿篡改本地数据'});
                let params = {uuid: uuid};
                axios.post('https://req'+ Math.ceil(Math.random() * 1) +'.chamas.cn/comment/praise', qs.stringify(params)).then(
                    response=> {
                        if(response.data && response.data == 200){
                            this.$notify({title: '成功',message: '评论点赞成功',type: 'success'});
                        }else if(response.data && response.data == 404){
                            this.$notify.error({title: '错误', message: '请勿篡改本地数据'});
                        }
                    },
                    error=> {
                        setTimeout( ()=> {
                            this.loadingHide();
                            this.$notify.error({title: '错误', message: '评论点赞失败'});
                        }, 800)
                    }
                )
            },
            alloc(uuid){
                if(! uuid) this.$notify.error({title: '错误', message: '请勿篡改本地数据'});
                this.edit_from.reply = uuid;
                this.$nextTick( ()=> {
                    this.$refs.content.focus();
                })
            },
            aite(uuid, name){
                if(! uuid || ! name) this.$notify.error({title: '错误', message: '请勿篡改本地数据'});
                this.edit_from.reply = uuid;
                this.edit_from.content = "@"+ name +"：";
                this.$nextTick( ()=> {
                    this.$refs.content.focus();
                })
            },
            submit(){
                this.loadingShow();
                setTimeout( ()=> {
                    this.loadingHide();
                    this.$notify.error({title: '错误', message: '评论功能暂未开放'});
                }, 200)
                return;

                if(! this.edit_from.content){
                    setTimeout( ()=> {
                        this.loadingHide();
                        this.$notify.error({title: '错误', message: '评论内容不能为空'});
                    }, 800)
                    return;
                }
                if(! this.mailMatch){
                    setTimeout( ()=> {
                        this.loadingHide();
                        this.$notify.error({title: '错误', message: '邮箱地址有误'});
                    }, 800)
                    return;
                }

                let params = {
                    title: this.title,
                    name: this.edit_from.name,
                    email: this.edit_from.mail,
                    content: this.edit_from.content,
                    reply: this.edit_from.reply ? this.edit_from.reply : null
                };
                axios.post('https://req'+ Math.ceil(Math.random() * 1) +'.chamas.cn/comment/insert', qs.stringify(params)).then(
                    response=> {
                        if(response.data && response.data == 200){
                            setTimeout( ()=> {
                                this.loadingHide();
                                this.$notify({title: '成功',message: '文章评论成功',type: 'success'});
                            }, 800)
                        }else if(response.data && response.data == 404){
                            setTimeout( ()=> {
                                this.loadingHide();
                                this.$notify.error({title: '错误', message: '请勿篡改本地数据'});
                            }, 800)
                        }else if(response.data && response.data == 500){
                            setTimeout( ()=> {
                                this.loadingHide();
                                this.$notify.error({title: '错误', message: '昵称与邮箱地址不相符'});
                            }, 800)
                        }
                    },
                    error=> {
                        setTimeout( ()=> {
                            this.loadingHide();
                            this.$notify.error({title: '错误', message: '文章评论失败'});
                        }, 800)
                    }
                )
            }
        },
        computed: {
            mailMatch(){
                if(! this.edit_from.mail) return false;
                let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                if(reg.test(this.edit_from.mail)) return true;
                return false;
            },
            disabled(){
                if(! this.edit_from.name) return true;
                if(! this.edit_from.mail) return true;
                return false;
            }
        },
        filters: {
            dateFormat(val){
                if(! val) return;
                if(! dateValid(val)) return dayjs().format("YYYY-MM-DD hh:mm:ss");

                if (val.toString().length === 10) {
                    return dayjs(val * 1000).format("YYYY-MM-DD hh:mm:ss");
                } else {
                    return dayjs(val).format("YYYY-MM-DD hh:mm:ss");
                }
            }
        },
        watch: {
            "title": {
                immediate: true,
                handler(val, old){
                    this.edit_from.content = "";
                    this.edit_from.name = "";
                    this.edit_from.mail = "";
                }
            }
        }
    }
</script>


<style scoped>
    .article-comment {
        margin-top: 2em;
        width: 100%;
        position: relative;
    }
    .article-comment :after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .comment-header {
        font-family: "华文楷体";
        font-size: 1.48em;
        font-weight: 1000;
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .comment-header i {
        float: left;
        margin-top: 0.5em;
        margin-right: .5em;
    }
    .article-comment .el-divider--horizontal {
        margin: 0;
        padding-top: 1px;
        padding-bottom: 1px;
    }
</style>

<style scoped>
    .comment-lists {
        margin-top: 1em;
        width: 100%;
    }
    .lists-item {
        margin-bottom: 1.5em;
        width: 100%;
    }
    .item-subject {
        width: 100%;
        max-height: 5em;
    }
    .item-subject:after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .subject-head {
        float: left;
        width: 3.5%;
    }
    .subject-head .el-avatar--small {
        opacity: .7;
        width: 1.8em;
        height: 1.8em;
        line-height: 20px;
        margin-top: 5px;
    }
    .subject-action {
        float: left;
        width: 96.5%;
    }
    .action-name {
        color: #5E5E5E;
        margin-top: .18em;
        font-size: 1.48em;
        font-family: "华文行楷";
    }
    .action-content {
        max-height: 4em;
        overflow: hidden;
        font-size: 1.24em;
        font-family: "华文楷体";
    }
    .action-dynamic {
        margin-top: .25em;
    }
    .action-dynamic::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .dynamic-support, .dynamic-comment, .dynamic-date {
        float: left;
    }
    .action-dynamic div{
        font-family: "华文仿宋";
        font-size: .98em;
        color: #ACACAC;
        float: left;
        vertical-align: bottom !important;
    }
    .dynamic-support i {
        float: left;
        margin-right: 4px;
        margin-top: .1em;
    }
    .dynamic-comment i, .dynamic-date i {
        float: left;
        margin-left: 8px;
        margin-right: 4px;
        margin-top: .18em;
    }


    .item-reply-lists {
        margin-top: .5em;
        float: left;
        width: 96.5%;
        margin-left: 3.5%;
    }
    .reply-item {
        width: 100%;
    }
    .reply-item::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .reply-head {
        float: left;
        width: 3.5%;
    }
    .reply-head .el-avatar--small {
        opacity: .7;
        width: 1.8em;
        height: 1.8em;
        line-height: 20px;
        margin-top: 5px;
    }
    .reply-action {
        float: left;
        width: 96.5%;
    }
    .reply-name {
        color: #5E5E5E;
        margin-top: .18em;
        font-size: 1.48em;
        font-family: "华文行楷";
    }
    .reply-content {
        max-height: 4em;
        overflow: hidden;
        font-size: 1.24em;
        font-family: "华文楷体";
    }
    .reply-dynamic {
        margin-top: .25em;
    }
    .reply-dynamic::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .reply-support, .reply-comment, .reply-date {
        float: left;
    }
    .reply-dynamic div{
        font-family: "华文仿宋";
        font-size: .98em;
        color: #ACACAC;
        float: left;
        vertical-align: bottom !important;
    }
    .reply-support i {
        float: left;
        margin-top: .1em;
        margin-right: 4px;
    }
    .reply-comment i, .reply-date i {
        float: left;
        margin-left: 8px;
        margin-right: 4px;
        margin-top: .18em;
    }
    .dynamic-support:hover, .dynamic-comment:hover, .reply-support:hover, .reply-comment:hover {
        cursor: pointer;
        color: #49b1f5;
    }
</style>

<style scoped>
    .comment-edit {
        margin-top: 2em;
        width: 100%;
    }
    .edit-button {
        text-align: center;
        font-family: "华文行楷";
        font-size: 1.24em;
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .edit-button i {
        cursor: pointer;
        font-size: 1.2em;
        font-weight: 1000;
        color: #49b1f5;
    }

    .from-content, .from-name, .from-mail {
        box-shadow: 0 0 8px 8px rgba(232,237,250,0.6), 0 2px 8px 8px rgba(232,237,250,0.5);
    }
    .from-content {
        margin-bottom: .5em;
    }
    .from-name, .from-mail {
        width: 43.75%;
        float: left;
        margin-right: 1.5%;
    }
    .from-submit {
        width: 9.5%;
        float: left;
    }
    .from-submit .el-buttom{
        padding: 10.5px 16px;
        font-size: 14px;
    }
    .from-submit i {
        float: left;
        margin-left: -.35em;
        margin-right: 0.35em;
    }
</style>