
<template>
    <div>
        <div class="asia-headline">
            <i class="iconfont icon-lishijilu"></i>
            最新文章
        </div>
        <div class="recent-posts">
            <div class="post-item" v-for="(article, index) in getRecent" :class="hasBorder(index < getRecent.length-1)" :key="index">
                <div class="post-cover">
                    <router-link  :to="`/article/${article.title}/`">
                        <el-image :src="article.cover" lazy>
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </router-link>
                </div>
                <div class="post-info">
                    <div class="info-title"><router-link  :to="`/article/${article.title}/`">{{article.title}}</router-link></div>
                    <div class="info-push">{{article.dynamic.push | pushFomat}}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import dayjs from 'dayjs';
    import {mapGetters} from 'vuex';
    export default {
        name: "RecentPost",
        data(){
            return{

            }
        },
        methods: {
            hasBorder(boolean){
                if(boolean) return "item-border";
                return null;
            }
        },
        filters: {
            pushFomat(val){
                if (val.toString().length === 10) {
                    return dayjs(val * 1000).format("YYYY-MM-DD");
                } else {
                    return dayjs(val).format("YYYY-MM-DD");
                }
            }
        },
        computed: {
            ...mapGetters('article', ['getRecent']),
        }
    }
</script>


<style scoped>
    .asia-headline, .recent-posts {
        padding-left: 10%;
        padding-right: 10%;
        width: 80%;
    }
    .asia-headline {
        padding-top: 1em;
        font-size: 1.2em;
        font-family: "华文楷体";
        padding-bottom: .2em;
    }
    .recent-posts {
        padding-top: .2em;
        padding-bottom: 1em;
    }


    .post-item {
        padding-top: .3em;
        padding-bottom: .3em;
        width: 100%;
        height: 4em;
        display: flex;
        display: -webkit-flex;
    }
    .item-border {
        border-bottom: 1px dashed #fcfcfc;
    }
    .post-cover {
        float: left;
        width: 38.4%;
        height: 4em;
    }
    .post-cover a {
        color: #585858;
    }
    .post-cover .el-image:hover {
        transform: scale(1.15);
    }
    .post-cover a, .el-image, .el-image__inner {
        height: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        text-align: center;
        display: flex;
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        align-items: center;
        flex-direction: row;
		justify-content: center;
    }

    .post-info {
        float: right;
        width: 61.6%;
        height: 4em;
    }
    .info-title {
        height: 65%;
        margin-left: 5px;
        font-family: "华文楷体";
        font-size: 1.01em;
        line-height: 1.3;
        overflow: hidden;
    }
    .info-title a {
        text-decoration: none;
        color: black;
    }
    .info-title a:hover {
        color: #49b1f5;
    }
    .info-push {
        height: 35%;
        font-family: "华文仿宋";
        font-size: 1em;
        color: #808080;
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
    }

</style>