
<template>
    <div id="about">
        <div id="page-bg"><Background :background="background" /></div>
        <div id="site-title">
            <div class="title-span">关于作者</div>
        </div>
        <div id="layout-content">
            <div class="layout">
                <div class="about-contents">
                    <div class="about" v-html="aboutFomat"></div>
                </div>
                <div class="aside-contents"><AsidePublic :witticism="witticism"/></div>
            </div>
        </div>
    </div>
</template>


<script>
    import {marked} from 'marked';
    import {initialApi} from '@/api';
    import {mapGetters, mapMutations} from 'vuex';
    import Background from '@/components/overall/Background.vue';
    import AsidePublic from '@/components/aside/AsidePublic.vue';
    export default {
        name: "About",
        props: ["BackTop"],
        components: {Background, AsidePublic},
        data(){
            return{
                background: null,
                witticism: null,
                mdContent: null
            }
        },
        methods: {
            ...mapMutations('popup', {loadingShow: 'LOADING_SHOW', loadingHide: 'LOADING_HIDE'})
        },
        computed: {
            ...mapGetters('atlas', ['wallpaperRandom']),
            ...mapGetters('witticism', ['essayRandom']),
            aboutFomat(){
                if(! this.mdContent) return;
                return marked(this.mdContent);
            }
        },
        mounted(){
            this.loadingShow();
            let renderer = new marked.Renderer();
            renderer.link = (href,title,text)=> {
                return '<a href="'+ href +'" title="'+ title +'" target="_blank">'+ text +'</a>';
            }
            marked.setOptions({
                renderer,
                gfm: true,
                tables: true,
                breaks: true,
                pedantic: false,
                sanitize: false,
                smartLists: true,
                smartypants: false
            })
            Promise.all([
                new Promise( (resolve)=> {
                    initialApi.information_about().then(
                        response => {
                            if(response.status === 200){
                                this.mdContent = response.data;
                            }
                            resolve();
                        },
                        error => {
                            this.$notify.error({title: '错误', message: error});
                            resolve();
                        }
                    )
                }),
                new Promise( (resolve)=> {
                    let background = this.wallpaperRandom();
                    if(background){
                        this.background = background;
                        resolve();
                        return;
                    }
                    initialApi.random_wallpaper().then(
                        response => {
                            if(response.status === 200){
                                this.background = response.data.route;
                            }
                            resolve();
                        },
                        error => {
                            this.$notify.error({title: '错误', message: error});
                            resolve();
                        }
                    )
                }),
                new Promise( (resolve)=> {
                    let essay = this.essayRandom();
                    if(essay){
                        resolve(essay);
                        return;
                    }
                    initialApi.random_essay().then(
                        response => {
                            let _essay;
                            if(response.status === 200){
                                _essay = response.data;
                            }
                            resolve(_essay ? _essay : null);
                        },
                        error => {
                            this.$notify.error({title: '错误', message: error});
                            resolve();
                        }
                    )
                })
            ]).then( (res)=> {
                this.BackTop();
                setTimeout( ()=> {
                    if(res[2]) this.witticism = res[2];
                    this.loadingHide();
                }, 500)
            })
        }
    }
</script>


<style scoped>
    #about {
        width: 100%;
    }
    #site-title, #layout-content {
        display: -webkit-flex; /* Safari */
        -webkit-align-items: center; /* Safari 7.0+ */
        display: flex;
        align-items: center;
        flex-direction: row;
		justify-content: center;
        position: relative;
        width: 100%;
    }
    #site-title {
        padding-top: 18em;
        padding-bottom: 2.8em;
    }

    .title-span {
        font-family: "华文行楷";
        font-size: 4em;
        color: rgba(255, 255, 255, .8);
        text-shadow: 0.1rem 0.1rem 0.2rem rgb(0 0 0 / 30%);
    }
    .layout {
        padding-top: 2.5em;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 2.5em;
        width: 100%;
        max-width: 1200px;
    }
    .about-contents {
        width: 76%;
        float: left;
    }
    .aside-contents {
        width: 23%;
        float: right;
        position: -webkit-sticky;
        position: sticky;
        top: -31.5em;
    }
    .about {
        width: 90%;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        padding-left: 5%;
        padding-right: 5%;
        border-radius: 8px;
        background: rgba(255, 255, 255, .9);
        box-shadow: 0 10px 10px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .04);
    }
</style>

<style>
    #layout-content .about-contents .about h1 {
        margin-top: .8em;
        margin-bottom: .25em;
    }
    #layout-content .about-contents .about h1, h2, h3 {
        color: #515151;
    }
    #layout-content .about-contents .about p {
        line-height: 1.5;
        padding-top: .55em;
        padding-bottom: .55em;
    }
    #layout-content .about-contents .about blockquote {
        margin: 0 0 1rem;
        padding: 0.1rem 0.8rem;
        border-left: 0.2rem solid #49b1f5;
        background-color: rgba(100, 250, 250, 0.2);
        color: #323B45;
        border-top-right-radius: .25em;
        border-bottom-right-radius: .25em;
    }
    #layout-content .about-contents .about img {
        width: 100%;
        height: 100%;
        border-radius: .25em;
    }
    #layout-content .about-contents .about ul {
        margin-left: 2em;
        margin-top: 0.4rem;
    }
    #layout-content .about-contents .about ul li {
        padding-left: .3rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    #layout-content .about-contents .about a {
        color: #127abe;
        text-decoration: none;
    }
    #layout-content .about-contents .about a:hover {
        text-decoration: underline;
    }

    #layout-content .about-contents .about pre {
        background: #363636;
        border-radius: .5em;
        margin-bottom: 1.5em;
    }

    #layout-content .about-contents .about pre code p {
        line-height: 1.5;
        font-size: 1.02em;
        font-family: "宋体";
        color: #fff;
    }
    #layout-content .about-contents .about pre code .code-serial {
        position: absolute;
        left: 1em;
        top: .6em;
        user-select: none;
        pointer-events: none;
        color: #8D8D8D8D;
    }
    #layout-content .about-contents .about pre code .code-content{
        position: relative;
        padding-left: 3.5em;
        padding-right: 3.5em;
        word-break:break-all;
        white-space:pre-wrap;
    }
    #layout-content .about-contents .about pre code .code-interval {
        margin-top: -2em;
    }
    #layout-content .about-contents .about pre code .code-annotation {
        color: #B0B0B0;
        font-family: "华文楷体";
    }
</style>